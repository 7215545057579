<div class="add-Product-wrapper">
    <div class="form-head-title flex-sb items-center flex">
        <h3 class="title-text" *ngIf="!data.product_id">Add Product</h3>
        <h3 class="title-text" *ngIf="data.product_id">Edit Product</h3>
        <img class="close-btn" src="../../../../../../assets/images/cancel-icon.svg" alt="" (click)="cancelDialog(null, true)" />
    </div>

    <div class="create-stream-container">
        <div class="tab-wrapper">
            <div class="tab-container flex">
                <ul class="nav">
                    <li
                        *ngFor="let sellTypeItem of allowedSellTypes"
                        class="nav-item"
                        (click)="sellTypeChange(sellTypeItem)"
                    >
                        <div class="nav-link tab-link" [ngClass]="{active: sellTypeItem === sellType}">
                            {{ sellTypesTitles[sellTypeItem] }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="flex align-items-flex-start upload-wrapper">
            <div class="flex justify-content-center align-items-center upload" *ngIf="!imageName">
                <label>
                    <img src="../../../../../assets/images/add-img.svg" />
                </label>
            </div>
            <div class="flex justify-content-center align-items-center upload" *ngIf="imageName">
                <img *ngIf="imageData" [src]="imageData">
                <advanced-image *ngIf="urlImage" [cldImg]="urlImage"></advanced-image>
            </div>
            <div class="right-part">
                <div class="flex align-items-center">
                    <label for="imglabel">
                        <h4 class="title">{{data.product_id ? "Update Product Thumbnail" : "Add Product Thumbnail"}}</h4>
                    </label>
                    <input type="file" accept="image/jpeg, image/png, image/jpg" style="display: none" id="imglabel"
                        class="control-input" (change)="uploadImage($event)" #imglabel />
                </div>
                <p class="text">Must be JPEG, JPG, PNG and cannot exceed 10MB.</p>
            </div>
            <span class="text-danger image-validation" *ngIf="formSubmitted && !imageName">Image is required*</span>
        </div>

        <div class="tab-content">
            <div class="tab-pane active">
                <form [formGroup]="productFormGroup">
                    <div
                        class="form-container"
                    >
                        <div
                            *ngIf="sellType === productSellTypes.GIVEAWAY"
                            class="giveaway-rules"
                        >
                            <h3 class="text-title">Giveaway Rules</h3>
                            <ul class="rules-list">
                                <li><span class="number">1.</span>Sellers are responsible for shipping costs of
                                    Giveaways products. We'll
                                    automatically charge the shipping cost from your Blazing Cards account balance.</li>
                                <li><span class="number">2.</span>In order for users to enter the Giveaway, you must pin
                                    your product to the stream.</li>
                                <li><span class="number">3.</span>Once you tap “Draw Winner” a random winner will be
                                    seleced; only entered viewers can win.</li>
                                <li><span class="number">4.</span> Must be present to win: entrants whi have left the
                                    stream are automatially removed a the time of drawing: winners are not required to
                                    react in any way to claim their prize.</li>

                            </ul>
                        </div>
                        <div class="form-wrapper">
                            <div class="form-col">
                                <label class="lable-text" for="">Primary Category*</label>
                                <select id="drpDwnPrimaryCategory" class="control-input category"
                                    formControlName="category" (change)="onPrimaryCategoryChange($event)" required
                                >
                                    <option value="" selected disabled hidden>Select Primary Category</option>
                                    <option *ngFor="let cat of parentCategories; trackBy: trackItem"
                                        [value]="cat.categoryId">{{cat.name}}</option>
                                </select>
                                <span class="text-danger" *ngIf="productFormGroup.controls.category.errors?.['required'] && productFormGroup.controls.category.touched">
                                    Category is required*
                                </span>
                            </div>
                            <div class="form-col">
                                <label class="lable-text" for="">Secondary Category*</label>
                                <select id="drpDwnSecondaryCategory" class="control-input category"
                                    formControlName="secondaryCategory" required
                                >
                                    <option value="" selected disabled hidden>Select Secondary Category</option>
                                    <option *ngFor="let cat1 of secondaryCategories; trackBy: trackItem"
                                        [value]="cat1.categoryId">{{cat1.name}}</option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="productFormGroup.controls['secondaryCategory'].hasError('required') && productFormGroup.controls.secondaryCategory.touched">Secondary
                                    Category is required* </span>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="form-col">
                                <label class="lable-text" for="">Product Name*</label>
                                <input class="control-input" (keypress)="numericAlphaOnly($event)"
                                    formControlName="name" type="text" placeholder="Enter Name"
                                    (keypress)="numericAlphaOnly($event)" required>
                                <span class="text-danger" *ngIf="productFormGroup.controls.name.touched">
                                    <span *ngIf="productFormGroup.controls['name'].hasError('required')">
                                        Name is required*
                                    </span>
                                    <span class="" *ngIf="productFormGroup.controls.name.errors?.['minlength']">
                                        The name must be at least three characters long.
                                    </span>
                                    <span class="" *ngIf="productFormGroup.controls.name.errors?.['maxlength']">
                                        The name cannot exceed 500 characters.
                                    </span>
                                </span>
                            </div>
                            <div class="form-col">
                                <label class="lable-text" for="">Start Price($)*</label>
                                <input
                                    class="control-input"
                                    type="number"
                                    (input)="inputValidators.restrictDecimals($event)"
                                    formControlName="price"
                                    placeholder="Enter Price"
                                >
                                <ng-container *ngIf="productFormGroup.controls.price.touched">
                                    <span class="text-danger" *ngIf="productFormGroup.controls['price'].hasError('required')">Price is required*</span>
                                    <span class="text-danger" *ngIf="productFormGroup.controls['price'].hasError('min')">Min price is {{productFormGroup.controls['price'].errors.min.min}}$</span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="form-col">
                                <label class="lable-text" for="">Quantity*</label>
                                <input class="control-input" type="number"
                                    formControlName="quantity" min="1" (keypress)="numberOnly($event)"
                                    placeholder="Enter Quantity" required>
                                <span *ngIf="productFormGroup.controls.quantity.touched">
                                    <span class="text-danger"
                                        *ngIf="productFormGroup.controls['quantity'].hasError('required')">
                                        Quantity is required*
                                    </span>
                                </span>
                            </div>
                            <div class="form-col">
                                <label class="lable-text" for="">Product Height (Inches)*</label>
                                <input class="control-input" type="number" min="1" max="15"
                                    maxlength="2" (input)="inputValidators.restrictDecimals($event)" formControlName="prodHeight"
                                    placeholder="Enter Height" required>
                                <span class="text-danger"
                                    *ngIf="productFormGroup.controls['prodHeight'].hasError('required') && productFormGroup.controls.prodHeight.touched">Height
                                    is required* </span>
                                <span class="text-danger"
                                    *ngIf="productFormGroup.controls['prodHeight'].value > 15 && productFormGroup.controls.prodHeight.touched">Max
                                    Height allowed is 15</span>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="form-col">
                                <label class="lable-text" for="">Product Width (Inches)*</label>
                                <input class="control-input" type="number" min="1" max="18"
                                    maxlength="2" (input)="inputValidators.restrictDecimals($event)" formControlName="prodWidth"
                                    placeholder="Enter Width" required>
                                <span class="text-danger"
                                    *ngIf="productFormGroup.controls['prodWidth'].hasError('required') && productFormGroup.controls.prodWidth.touched">Width
                                    is required* </span>
                                <span class="text-danger"
                                    *ngIf="productFormGroup.controls['prodWidth'].value > 18 && productFormGroup.controls.prodWidth.touched">Max
                                    Width allowed is 18</span>
                            </div>
                            <div class="form-col">
                                <label class="lable-text" for="">Product Length (Inches)*</label>
                                <input class="control-input" type="number" min="1" max="22"
                                    maxlength="2" (input)="inputValidators.restrictDecimals($event)" formControlName="prodLength"
                                    placeholder="Enter Length" required>
                                <span class="text-danger"
                                    *ngIf="productFormGroup.controls['prodLength'].hasError('required') && productFormGroup.controls.prodLength.touched">Length
                                    is required* </span>
                                <span class="text-danger"
                                    *ngIf="productFormGroup.controls['prodLength'].value > 22 && productFormGroup.controls.prodLength.touched">Max
                                    Length allowed is 22 </span>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="form-col">
                                <label class="lable-text" for="">Product Weight (Ounces)*</label>
                                <input
                                    class="control-input"
                                    type="number"
                                    min="1"
                                    (input)="inputValidators.restrictDecimals($event)" formControlName="prodWeight"
                                    placeholder="Enter Weight"
                                    required
                                >
                                <span
                                    *ngIf="productFormGroup.controls['prodWeight'].hasError('required') && productFormGroup.controls.prodWeight.touched"
                                    class="text-danger"
                                >
                                Weight is required*
                                </span>
                            </div>
                            <div class="form-col">
                                <label class="lable-text" for="">Shipping Profile*</label>
                                <select id="drpDwnShippingProfile" class="control-input"
                                    formControlName="shippingProfile" required>
                                    <option value="" selected disabled hidden>Select Shipping Profile</option>
                                    <option *ngFor="let sp of shippingProfiles; trackBy: trackSP" [value]="sp.sp_id">
                                        {{sp.sp_profile_name}}</option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="productFormGroup.controls['shippingProfile'].hasError('required') && productFormGroup.controls.shippingProfile.touched">Shipping
                                    Profile is required* </span>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="form-col select-cat">
                                <label>Product Quality*</label>
                                <div class="quality">
                                   <button class="qlt-btn" [ngClass]="quality == 'mint' ? 'active-btn' : ''" (click)="quality = 'mint'">Mint</button>
                                   <button class="qlt-btn" [ngClass]="quality == 'good' ? 'active-btn' : ''" (click)="quality = 'good'">Good</button>
                                   <button class="qlt-btn" [ngClass]="quality == 'damaged' ? 'active-btn' : ''" (click)="quality = 'damaged'">Damaged</button>
                                   <span class="text-danger" *ngIf="formSubmitted && !quality">Product Quality is required* </span>
                                </div>
                            </div>
                            <div class="form-col">
                                <label>Years Of Manufacture*</label>
                                <select class="control-input" formControlName="year">
                                  <option value="">Select Year</option>
                                  <option value="2023">2023</option>
                                  <option value="2022">2022</option>
                                  <option value="2021">2021</option>
                                  <option value="2020">2020</option>
                                  <option value="2019">2019</option>
                                  <option value="2018">2018</option>
                                  <option value="2017">2017</option>
                                  <option value="2016">2016</option>
                                  <option value="2015">2015</option>
                                  <option value="2014">2014</option>
                                  <option value="2013">2013</option>
                                  <option value="2012">2012</option>
                                  <option value="2011">2011</option>
                                  <option value="2010">2010</option>
                                  <option value="2009">2009</option>
                                  <option value="2008">2008</option>
                                  <option value="2007">2007</option>
                                  <option value="2006">2006</option>
                                  <option value="2005">2005</option>
                                  <option value="2004">2004</option>
                                  <option value="2003">2003</option>
                                  <option value="2002">2002</option>
                                  <option value="2001">2001</option>
                                </select>
                                <span class="text-danger" *ngIf="productFormGroup.controls['year'].hasError('required') && productFormGroup.controls.year.touched">Year of Manufacture is required* </span>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="form-col description">
                                <label class="lable-text" for="">Product Description*</label>
                                <textarea name="" id="" rows="6" class="control-input" formControlName="description" type="text" placeholder="Enter Description" required></textarea>
                                <span class="text-danger" *ngIf="productFormGroup.controls.description.touched">
                                    <span *ngIf="productFormGroup.controls.description.errors?.['required']">
                                        Description is required*
                                    </span>
                                    <span *ngIf="productFormGroup.controls.description.errors?.['minlength']">
                                        The description must be at least five characters long.
                                    </span>
                                    <span *ngIf="productFormGroup.controls.description.errors?.['maxlength']">
                                        The description cannot exceed 1000 characters.
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div
                            *ngIf="sellType === productSellTypes.GIVEAWAY"
                            class="content-setting"
                        >
                            <h4 class="title">Giveaway Settings</h4>
                            <div class="inner-content flex justify-content-between">
                                <div class="left-part">
                                    <h5>Allow followers</h5>
                                    <p class="description">
                                        Turn this on if you want to allow only your followers to enter the giveaway.
                                        <a href="">Learn More</a>
                                    </p>
                                </div>
                                <div class="right-part">
                                    <label class="switch">
                                        <input type="checkbox" class="control-input" [checked]="isClick"
                                            (change)="toggleChangefollow($event)" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-action flex items-center">
                        <button class="border-btn cancel-btn" (click)="cancelDialog(null, true)">Cancel</button>
                        <button
                            type="submit"
                            class="form-btn primary-btn"
                            (click)="onSubmit()"
                        >
                            {{ data.product_id ? "Update Product" : "Add Product"}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
     </div>
</div>