import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { StreamApiService } from '../../../../../core/stream/stream.service';

@Component({
  selector: 'app-create-auction',
  templateUrl: './create-auction.component.html',
  styleUrls: ['./create-auction.component.scss']
})

export class CreateAuctionComponent implements OnInit {

  createAuctionFormGroup: FormGroup;
  isActive : boolean = false;
  time : string = '30s';
  totelTime : number = 30; 
  sd: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<CreateAuctionComponent>, 
    public streamService: StreamApiService, 
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.createAuctionFormGroup = new FormGroup({
      price: new FormControl(Math.ceil(this.data.productDetails.price), [Validators.required]),
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

  /**
  * @method -- timeInactive for inactive
  */
  timeInactive() {
    this.isActive = false;
  }

    /**
   * @method -- timeActive for active
   */
  timeActive() {
    this.isActive = true;
  }

  /**
   * @method -- addTime -- add time
   * @param data  -- time
   */
  addTime(data) {
    this.time = data;
    if(this.time === '15s') {
      this.totelTime = 15;
    }
    else if(this.time === '30s') {
      this.totelTime = 30;
    }
    else if(this.time === '45s') {
      this.totelTime = 45;
    }
    else if(this.time === '1mins') {
      this.totelTime = 60;
    }
    else if(this.time === '1mins 30s') {
      this.totelTime = 90;
    }
    else if(this.time === '2mins') {
      this.totelTime = 120;
    }
    else if(this.time === '2mins 30s') {
      this.totelTime = 150;
    }
    else if(this.time === '3mins') {
      this.totelTime = 180;
    }
    else if(this.time === '3mins 30s') {
      this.totelTime = 210;
    }
    else if(this.time === '4mins') {
      this.totelTime = 240;
    }
    else if(this.time === '4mins 30s') {
      this.totelTime = 270;
    }
    else {
      this.totelTime = 300;
    }
  }

  toggleChange(event) {
    this.sd = event.target.checked;
  }

  onSubmit() {
    Object.keys(this.createAuctionFormGroup.controls).forEach((field) => {
      const control = this.createAuctionFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.createAuctionFormGroup.status === "VALID") {
      let params: any = {};
      params.stream_product_id = this.data.productDetails.stream_product_id;
      params.start_time = this.totelTime;
      params.base_price = this.createAuctionFormGroup.value.price;
      params.suddenDeath = this.sd;
      
      this.streamService.createAuction(params).subscribe(data => { 
        if (data && data.status === 1) {
          this.toastr.success(data.message);
          this.dialogRef.close({ 
            auctionId: data.data.id, 
            time: this.totelTime, 
            price: this.createAuctionFormGroup.value.price, 
            stream_product_id: this.data.productDetails.stream_product_id, 
            name : this.data.productDetails.name,
            description: this.data.productDetails.description,
            sell_type: this.data.productDetails.sell_type,
            product_id: this.data.productDetails.product_id
          });
        } 
        else {
          this.toastr.error(data.message);
        }
      });
    }
  }
}