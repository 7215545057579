import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Cloudinary } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import axios from "axios";
import { DeviceDetectorService } from "ngx-device-detector";
import { IProduct, IStream, ProductSellType } from '../../interfaces/interface';
import { environment } from '../../../../../environments/environment';
import { MediaService } from '../../../../core/media/media.service';
import { StreamApiService } from '../../../../core/stream/stream.service';
import { MediaSandbox } from '../../../../core/media/media.sandbox';
import { StreamSandbox } from '../../../../core/stream/stream.sandbox';
import { Subscription } from 'rxjs';
import { InputsValidators } from '../../../../core/shared/utility/utilityHelpers';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { IStreamProduct } from '../../../pages/component/stream/interfaces';

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.scss"],
})

export class AddProductComponent implements OnInit, OnDestroy {
  
  formSubmitted = false;
  sellTypesTitles = {
    [ProductSellType.AUCTION]: "Auction",
    [ProductSellType.BUY_NOW]: "By Now",
    [ProductSellType.GIVEAWAY]: "Giveaway",
    [ProductSellType.OFFLINE]: "Offline"
  };
  allowedSellTypes: ProductSellType[];
  productSellTypes = ProductSellType;
  productFormGroup: FormGroup;
  allCategories: any[] = [];
  parentCategories: any[] = [];
  secondaryCategories: any[] = [];
  shippingProfiles: [] = [];
  sellType = ProductSellType.OFFLINE;
  only_follower_allowed : boolean = false;
  isClick : boolean = false;
  quality: string;
  prevPriceSelection: number;
  inputValidators = InputsValidators;

  imageData : any;
  imageName : string = '';
  currentFolder: string = '';
  public vendorPrefixId = JSON.parse(localStorage.getItem('vendorUserDetails')).vendorPrefixId;
  public imageUrl = environment.imageUrl;
  public cloudName = environment.cloudName;
  public cloudFolder = environment.cloudFolder;
  urlImage: any;
  cld: any;
  params: { 
    path: string; 
    fileName: any; 
  };
  randomString : string = '';
  imageWithRandomString : string = '';

  categoriesSubscription = Subscription.EMPTY;

  @Output() submitted = new EventEmitter<IProduct>();

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: {
      allowedSellTypes: ProductSellType[]
      uuid: string;
      sellType: ProductSellType;
      product_id : number;
      isStreamProduct: boolean;
      closeAfterSubmit: boolean;
    },
    private toastr: ToastrService,
    public service : MediaService,
    public streamService: StreamApiService,
    public mediaSandbox: MediaSandbox, 
    public streamSandbox: StreamSandbox,
    public dialogRef: MatDialogRef<AddProductComponent>,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.allowedSellTypes = this.data.allowedSellTypes;
    this.sellType = this.data.sellType;
    this.currentFolder = this.vendorPrefixId;
    this.cld = new Cloudinary({
      cloud: {
        cloudName: this.cloudName,
      }
    });
    this.productFormGroup = new FormGroup({
      name: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      description: new FormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(1000)]),
      category: new FormControl("", [Validators.required]),
      secondaryCategory: new FormControl("", [Validators.required]),
      quantity: new FormControl(1, [Validators.required]),
      price: new FormControl(
        this.sellType === this.productSellTypes.GIVEAWAY ? 0 : "",
        [Validators.required, Validators.min(this.sellType === this.productSellTypes.GIVEAWAY ? 0 : 1)]
      ),
      prodHeight: new FormControl("", [Validators.required, Validators.min(1), Validators.max(15)]),
      prodWidth: new FormControl("", [Validators.required, Validators.min(1), Validators.max(18)]),
      prodLength: new FormControl("", [Validators.required, Validators.min(1), Validators.max(22)]),
      prodWeight: new FormControl("", [Validators.required, Validators.min(1)]),
      shippingProfile: new FormControl("", [Validators.required]),
      year: new FormControl("", [Validators.required]),
    });

    if (this.sellType === ProductSellType.GIVEAWAY) {
      this.productFormGroup.get('price').disable();
    }

    this.streamSandbox.getCategoryList();
    this.categoriesSubscription = this.streamSandbox.categoryListLoaded$.pipe(
      filter(Boolean),
      switchMap(() => this.streamSandbox.categoryList$),
      take(1)
    ).subscribe((data) => {
      if (data) {
        this.allCategories = data;
        this.parentCategories = data.filter(this.isParentCategory);

        if (this.data.uuid && !this.data.product_id) {
          this.getStreamUUID(this.data.uuid);
        }

        if (this.data.product_id) {
          this.getProduct();
        }
      }
    });

    this.getShippingProfiles();
  }

  ngOnDestroy(): void {
    this.categoriesSubscription.unsubscribe();
  }

  isParentCategory(value) {
    return value.parentInt === 0;
  }

  /**
  * @method -- get Stream Basedon UUID
  * @param uuid 
  */
  getStreamUUID(uuid): void {
    this.streamService.getStreamUUId(uuid).pipe(
      map(res => res.data),
      filter(Boolean)
    ).subscribe((data: IStream) => {
      this.secondaryCategories = this.allCategories.filter((c) => c.parentInt == data.category_id);
      this.productFormGroup.patchValue({
        category : data.category_id,
        secondaryCategory : data.subCategory_id
      });
    });
  }

  toggleChangefollow(event: any): void {
    this.only_follower_allowed = event.target.checked;
  }

  getProduct() {
    this.streamService.getProductID(this.data.product_id).subscribe((result) => {
      if(result?.status === 1) {
        const product = result.data;
        this.isClick = product.onlyFollowerAllowed;
        this.urlImage = this.cld.image(this.cloudFolder + "/"+ product.productImage[0].containerName + "/" + product.productImage[0].image);
        this.urlImage.resize(fill().width(88).height(125)).border(byRadius(8));      
        this.imageName = product.productImage[0].image;
        this.currentFolder = product.productImage[0].containerName;
        const secondaryCategoryId = product.Category?.[0]?.categoryId;
        const secondaryCategory = this.allCategories.find(category => category.categoryId === secondaryCategoryId);
        const parentCategoryId = secondaryCategory?.parentInt;
        this.secondaryCategories = this.allCategories.filter(c => c.parentInt == parentCategoryId);
        this.quality = product.productQuality;

        this.productFormGroup.patchValue({
          name : product.name,
          price : product.price.replace('.00',''),
          quantity : product.quantity,
          prodHeight : product.height.replace('.00',''),
          prodWidth : product.width.replace('.00',''),
          prodLength : product.length.replace('.00',''),
          prodWeight : product.weight.replace('.00',''),
          description : product.description,
          shippingProfile : product.shipping_profile_id,
          category : parentCategoryId,
          year: product.yearOfManufacture,
          secondaryCategory : secondaryCategoryId,
        })
      }
    })
  }

  getShippingProfiles(): void {
    this.streamService.getShippingProfiles().subscribe(data => {
      if (data) {
        this.shippingProfiles = data.data;
      }
    });
  }

  onPrimaryCategoryChange(event) {
    const categoryId = event.currentTarget.value;
    this.secondaryCategories = this.allCategories.filter(
      (c) => c.parentInt == categoryId
    );
    this.productFormGroup.controls.secondaryCategory.setValue('');
  }

  public trackItem(index: number, item: any) {
    return item.categoryId;
  }
  
  public trackSP(index: number, item: any) {
    return item.sp_id;
  }

  /**
  * @method numericAlphaOnly
  * @param event 
  * @returns 
  */
   numericAlphaOnly(e: any) : boolean  {
    var regex = new RegExp("^[a-zA-Z0-9_ ]*$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }

  /**
  * @method numericAlphaOnly
  * @param event 
  * @returns 
  */
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    
    return true;
  }  

  cancelDialog(product?: IProduct | IStreamProduct, isCancel = false): void {
    this.productFormGroup.reset();
    this.data.sellType = ProductSellType.OFFLINE;
    this.data.uuid = '';
    this.data.product_id = null;
    this.dialogRef.close({product, isCancel});
  }
  
  onSubmit(): void {
    if (this.productFormGroup.invalid || !this.quality || !this.imageName) {
      Object.keys(this.productFormGroup.controls).forEach((field) => {
        const control = this.productFormGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
      this.formSubmitted = true;
      return;
    }

    this.submitProduct();
  }

  submitProduct(): void {

    const params: any = this.getBaseParams();

    if (this.data.product_id) {
      params.categoryId = this.productFormGroup.value.secondaryCategory;

      this.streamService.updateProduct(params, this.data.product_id).subscribe((result : any) => {
        if (result && result.status === 1) {
          this.toastr.success(result.message);
          this.productFormGroup.reset();
          if (this.data.closeAfterSubmit) {
            this.cancelDialog();
          }
          this.submitted.emit(result.data);
        } else {
          this.toastr.error(result.message);
        }
      });
    } else {
      params.categoryId = [this.productFormGroup.value.secondaryCategory];

      this.streamService.addProduct(params).subscribe(data => {
        if (data && data.status === 1) {
          this.toastr.success(data.message);
          this.productFormGroup.reset();
          if (this.data.closeAfterSubmit) {
            this.cancelDialog(data.data);
          }
          this.submitted.emit(data.data);
        } else {
          this.toastr.error(data.message);
        }
      });
    }
  }

  uploadImage(event) {
    if(event.target.files[0].size > 10485760) {
      this.toastr.error('Please upload a image less than 10MB');
    }
    else {
      if(event.target.files[0]) {
        let imageData = event.target.files[0].name.toLowerCase();
        if(imageData.endsWith('jpg') || imageData.endsWith('jpeg') || imageData.endsWith('png')) {
          if(this.deviceService.os === 'iOS' && this.deviceService.device === 'iPhone' && this.deviceService.isMobile) {
            this.randomString = this.randomStringData(8);
            if(imageData === 'image.jpeg' || imageData === 'image.jpg' || imageData === 'image.png') {
              let imageWithRandomString = this.randomString + imageData;
              this.params = {
                path : this.currentFolder,
                fileName : imageWithRandomString
              }
              imageData = imageWithRandomString;
            }
            else {
              this.params = {
                path : this.currentFolder,
                fileName : imageData
              }
            }  
          }
          else {
            this.params = {
              path : this.currentFolder,
              fileName : imageData
            }
          }
          this.params['type'] = event.target.files[0].type;
          this.service.imageUpload(this.params).subscribe((result) => {
            if(result && result.status === 1 && result.url) {
              const options = {
                headers: {
                  'Content-Type': this.params['type'],
                  'X-Goog-Acl': 'public-read'
                }
              };
              axios.put<any>(result.url, event.target.files[0], options).then((response) => {
                if(response.status === 200) {
                  this.toastr.success('Success', 'File uploaded successfully');
                  this.convertBase64(event.target.files[0]);
                  this.imageName = imageData
                }
              }).catch((error) => {
                this.toastr.error('Image Not Uploaded!. Try again');
              }); 
            }
          })
        }
        else {
          this.toastr.error('Image must be JPEG, JPG, PNG');
        }
      }
    }
  }

  /**
   * @method -- random String
   * @param length 
   * @returns 
   */
   randomStringData(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  /**
  * @method convertBase64  -- convert to Base 64 format
  * @param image 
  */
  convertBase64(image): void {
    const file: File = image;
    const myReader: FileReader = new FileReader();
    myReader.onloadend = e => {
      this.imageData = myReader.result;
    };
    myReader.readAsDataURL(file);
  }

  sellTypeChange(sellType: ProductSellType): void {
    this.sellType = sellType;
    const priceControl = this.productFormGroup.get('price');

    if (sellType === this.productSellTypes.GIVEAWAY) {
      this.prevPriceSelection = priceControl.value;
      priceControl.setValue(0);
      priceControl.setValidators([Validators.required, Validators.min(0)]);
      priceControl.disable();
    } else {
      if (priceControl.disabled) {
        priceControl.enable();
      }

      priceControl.setValidators([Validators.required, Validators.min(1)]);

      if (this.prevPriceSelection) {
        priceControl.setValue(this.prevPriceSelection);
      }
    }

    priceControl.updateValueAndValidity();
  }

  private getBaseParams() {
    return {
      productName: this.productFormGroup.value.name, 
      productDescription: this.productFormGroup.value.description,
      price: this.productFormGroup.value.price || 0,
      quantity: this.productFormGroup.value.quantity,
      sellType: this.sellType, 
      height: this.productFormGroup.value.prodHeight,
      width: this.productFormGroup.value.prodWidth, 
      weight: this.productFormGroup.value.prodWeight, 
      length: this.productFormGroup.value.prodLength,
      shippingProfile: this.productFormGroup.value.shippingProfile,
      image: [{ containerName: this.currentFolder, image: this.imageName}],
      onlyFollowerAllowed: this.only_follower_allowed,
      ecomType: this.sellType === this.productSellTypes.OFFLINE ? 'general' : 'stream',
      yearOfManufacture: this.productFormGroup.value.year,
      productQuality: this.quality,
      ...(this.data.isStreamProduct && {streamUuid: this.data.uuid})
    };
  }
}