import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StreamApiService } from '../../../../../core/stream/stream.service';

@Component({
  selector: 'app-blocked-users-modal',
  templateUrl: './blocked-users-modal.component.html',
  styleUrls: ['./blocked-users-modal.component.scss']
})

export class BlockedUsersModalComponent implements OnInit {
  
  blocked: boolean = true;
  blockedUsers: any[] = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BlockedUsersModalComponent>,
    public service: StreamApiService,
  ) {}

  ngOnInit(): void {
    this.service.getBlockedUsers(this.data.uuid).subscribe(data => {
      if (data && data.status === 1) {
        this.blockedUsers = this.removeDupliactes(data.data);
        this.blockedUsers.forEach(u => {
          if(u.blocked_user_id === this.data.userId){
            this.blocked = false;
          }
        });
      }
    });
  }

  removeDupliactes = (values) => {
    let concatArray = values.map(eachValue => {
      return Object.values(eachValue).join('')
    })
    let filterValues = values.filter((value, index) => {
      return concatArray.indexOf(concatArray[index]) === index
    })
    return filterValues
  }

  /**
  * @method closeModal() - Its use for closeing Modal()
  */
   closeModal() {
    this.dialogRef.close();
  }

  blockUser() {
    const {vendorId} = JSON.parse(localStorage.getItem('vendorUserDetails'));
    let params = {
      "stream_uuid" : this.data.uuid,
      "vendor_id" : vendorId,
      "moderator_id" : vendorId,
      "blocked_user_id" : this.data.userId
    };
    this.service.blockUser(params).subscribe(data => {
      if (data && data.data && data.status === 1) {
        this.dialogRef.close();
      }
    });
  }
}