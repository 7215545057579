<app-breadcrumbs></app-breadcrumbs>
<div class="shipment-container">
    <div class="top-head-section flex items-center space-between">
        <div class="left-part flex items-center" *ngIf="previousStreams && previousStreams.length > 0">
            <div class="text">Select Stream</div>
            <div class="card-date">
                <select name="date" id="date" (change)="selectChangeHandler($event)" value="">
                    <option value="">Select Stream</option>
                    <option *ngFor="let pastStream of previousStreams" value={{pastStream.id}}>
                        {{pastStream.scheduleDate}} - {{pastStream.title}}
                    </option>
                </select>
            </div>
        </div>
        <div class="right-part">
            <ul class="sold-amount flex items-center space-between">
                <li>Total Sold Count</li>
                <li class="amount"> {{totalOrder}}</li>
            </ul>
            <ul class="earnd-amount flex items-center space-between">
                <li>Total Earned Amount</li>
                <li class="amount">${{earnedAmount | number : '1.2-2'}}</li>
            </ul>
        </div>
    </div>
    <div class="bottom-head-section flex items-center space-between">
        <div class="left-part">
            Shipments
        </div>
        <div class="button-action">
            <img src="../../../../../../assets/images/order-menu.svg" alt="" *ngIf="shipmentList.length > 0 && !isMobile" (click)="activeInMobile()">
            <img src="../../../../../../assets/images/order-menu.svg" alt="" *ngIf="isMobile" (click)="inActiveInMobile()">
        </div>
        <div class="right-part flex items-center" *ngIf="shipmentList.length > 0 && isMobile">
            <button class="border-btn" (click)="getCollapseData(streamId, limit, offset)" [ngClass]="isCollapse.length > 0 ? 'act-btn' : ''">Collapse All</button>
            <button class="border-btn" (click)="unbundleOrders()" [disabled]="isUnbundleButtonDisabled()" [ngClass]="isUnbundleButtonDisabled() ? '' : 'act-btn'">Unbundle Selected</button>
            <button class="border-btn" (click)="bundleOrders()" [disabled]="isBundleButtonDisabled()" [ngClass]="isBundleButtonDisabled() ? '' : 'act-btn'">Bundle Selected</button>
        </div>
    </div>
    <div class="date-container" *ngIf="shipmentList.length > 0">
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Shipment #</th>
                    <th>Buyer</th>
                    <th>Amount ($)</th>
                    <th>Shipping Charge ($)</th>
                    <th>Weight</th>
                    <th>Dimensions</th>
                    <th>Status</th>
                    <th>Tracking</th>
                    <th>Action</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let shipment of shipmentList">
                    <tr [ngClass]="shipment.sm_uuid ? '': 'active'">
                        <td>
                            <span class="drop-down" [ngClass]="shipment.sm_uuid ? '': 'active'" (click)="active(shipment.sm_uuid)">
                                <img src="../../../../../../assets/images/sp-down.svg" (click)="shipment.sm_uuid = !shipment.sm_uuid" />
                            </span>
                        </td>
                        <td>
                            <span class="check-action">
                                <input type="checkbox" [disabled]="shipment.sm_tracking_no && shipment.sm_tracking_no > 0" (click)="checkUncheckShipment($event, shipment.sm_id, shipment.sm_customer_id)">
                            </span>
                        </td>
                        <td>
                            <span class="id-num">
                                {{shipment.sm_id}}
                            </span>
                        </td>
                        <td>
                            {{shipment.customer_name}}
                        </td>
                        <td>
                            {{shipment.sm_amount * 1 + shipment.sm_shipping_amount * 1 + shipment.sm_tax * 1 | number}}
                        </td>
                        <td>
                            {{shipment.sm_shipping_amount * 1 | number}}
                        </td>
                        <td>
                            {{shipment.sm_weight.replace('.00', '')}} oz
                        </td>
                        <td>
                            {{shipment.sm_dimensions.replaceAll('.00', '')}} in
                        </td>
                        <td>
                            {{shipment.sm_order_status?.replace('_', ' ')}}
                        </td>
                        <td>
                            <span class="id-num">
                                <a href="{{shipment.sm_tracking_url}}" target="_blank">
                                    {{shipment.sm_tracking_no}}
                                </a>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="shipment.sm_tracking_no && shipment.sm_tracking_no > 0" class="border-btn" style="cursor: pointer;" (click)="printLabel(shipment.sm_id)">
                                <img src="../../../../../../../assets/images/print-icon.svg" alt="">
                                Print Label
                            </span>
                            <span *ngIf="!shipment.sm_tracking_no" class="primary-btn" style="cursor: pointer;" (click)="generateLabel(shipment.sm_id, shipment.sm_customer_id)">
                                Create Label
                            </span>                
                        </td>
                        <td>
                            <span class="edit" class="border-btn" *ngIf="!(shipment.sm_tracking_no && shipment.sm_tracking_no > 0)" (click)="editShipment(shipment)">
                                Edit
                            </span>
                        </td>
                    </tr>
                    <td id="#{{shipment.sm_uuid}}" [hidden]="shipment.sm_uuid" colspan="11" *ngIf="shipment.shipmentProducts && shipment.shipmentProducts.length > 0">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Order Id #</th>
                                    <th>Product</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Weight</th>
                                    <th>Price ($)</th>
                                    <th>Shipping Charge ($)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let shipmentOrder of shipment.shipmentProducts">
                                    <td></td>
                                    <td>
                                        <span class="check-action">
                                            <input type="checkbox" [disabled]="shipment.sm_tracking_no && shipment.sm_tracking_no > 0" (click)="checkUncheckOrders($event, shipment.sm_id, shipmentOrder.orderId)">
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {{shipmentOrder.orderId}}
                                        </span>
                                    </td>
                                    <td>
                                        {{shipmentOrder.productName}}
                                    </td>
                                    <td [innerHTML]="shipmentOrder.productDesc">
                                        
                                    </td>
                                    <td>
                                        {{shipmentOrder.order_status}}
                                    </td>
                                    <td>
                                        {{shipmentOrder.weight.replace('.00', '')}} oz
                                    </td>
                                    <td>
                                        {{shipmentOrder.amount * 1 + shipmentOrder.shippingAmount * 1 + shipmentOrder.tax * 1 | number}}
                                    </td>
                                    <td>
                                        {{shipmentOrder.shippingAmount * 1 | number}}
                                    </td>
                                </tr>
                            </tbody>
                       </table> 
                   </td>
               </ng-container>
           </tbody>
       </table>
   </div>
   <div class="no-list" *ngIf="shipmentList.length === 0">
        No shipment list available
   </div>
   <div class="showing-results-wrapper" *ngIf="countOrder > 0">
        <div class="showing-results">
            <div class="left-part">
                Showing 1-10 of {{countOrder}} results
            </div>
        </div>
        <div class="pager" *ngIf="countOrder > 0">
            <app-pager (pageChange)="pageChange($event)" [pageSize]="limit" [currentPage]="currentPage" [counts]="countOrder"></app-pager>
        </div>
    </div>
</div>