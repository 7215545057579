<div class="streaming-page flex">
  <div class="streaming-left">
    <div class="streamer-info-wrap">
      <img
        class="back-button"
        src="../../../../../../../assets/images/arrow-left.svg"
        style="cursor: pointer;"
        (click)="back()"
      >
      <div class="streamer-info">
        <advanced-image *ngIf='avatar' class="streamer-info-image" [cldImg]="avatar"></advanced-image>
        <div class="streamer-info-details">
          <div class="streamer-info-name">{{loadedStreamData?.vendorDetails?.first_name}} {{loadedStreamData?.vendorDetails?.last_name}}</div>
          <div class="select-dropdown">
            <ng-select
              class="categories"
              [items]="dropdownCategories" 
              [clearable]="false"
              [searchable]="false"
              [multiple]="false"
              placeholder="Select Category"
              [ngModel]="selectedCategory ? {value: selectedCategory, label: selectedCategory.name} : null"
              (change)="onStreamCategoryChange($event)"
            ></ng-select>
          </div>
          <div class="streamer-info-followers">{{loadedStreamData?.vendorDetails?.follower_count}} Follower{{loadedStreamData?.vendorDetails?.follower_count > 1 ? 's' : ''}}</div>
        </div>
      </div>
    </div>
    <div class="leftdata-wrapper">
      <div class="left-part">
        <div class="head-title">
          <h3 class="title" title="{{streamDetails.title}}">{{streamDetails.title.substring(0,30)}}<span *ngIf="streamDetails.title.length > 30">...</span></h3>
        </div>
      </div>
      <div class="select-dropdown select-menus">
        <span class="select-dropdown-text">Product menu:</span>
        <ng-select
          [items]="dropdownProductMenus" 
          [clearable]="false"
          [searchable]="false"
          [multiple]="false"
          placeholder="Select Product Menu"
          [ngModel]="selectedProductMenu ? {value: selectedProductMenu, label: selectedProductMenu.listName} : null"
          (change)="onProductMenuChange($event)"
        >
        </ng-select>
      </div>
      <div class="select-dropdown select-products">
        <span class="select-dropdown-text">Products:</span>
        <ng-select
          class="products-dropdown"
          [items]="dropdownProducts" 
          [clearable]="false"
          [searchable]="false"
          [multiple]="true"
          [closeOnSelect]="false"
          placeholder="Select Products"
          required
          [(ngModel)]="selectedProducts"
          (change)="selectedProductsChanged = true"
          (open)="onProductsDropdownOpen()"
          (close)="onProductsDropdownClose()"
        >
          <ng-template ng-multi-label-tmp let-items="items">
            <ng-container *ngIf="items?.length">
              <div class="ng-value ng-star-inserted">
                <span class="ng-value-label ng-star-inserted">{{ items[0].label }}</span>
              </div>
            </ng-container>
            <span *ngIf="items?.length > 1" class='count'> +{{ items.length - 1 }} more</span>
          </ng-template>
        </ng-select>
      </div>
      <mat-tab-group color="primary" (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Auction">
          <div class="search">
            <input type="text" placeholder="Search products..." (keyup)="searchAuctionProduct($event)" />
          </div> 
          <div class="purchased-list leftdata-list">
            <div class="product-count" *ngIf="!auctionProducts.length; else auctionProductsHeader"> No auction product available</div>
            <ng-template #auctionProductsHeader>
              <div class="product-count"> {{ auctionProducts.length }} {{ auctionProducts.length > 1 ? "Products" : "Product" }}</div>
            </ng-template>
            <div class="product-list">
              <div class="flex flex-sb list-data" [ngClass]="list-data-remove" *ngFor="let product of auctionProducts; trackBy: trackByProducts">
                <div class="left product-left-wrp">
                  <div class="inner-wrp flex column">
                    <strong>{{product.name}}</strong>
                    <span>{{product.quantity}} Available</span>
                    <span *ngIf="product.streamUuid !== loadedStreamData?.uuid && selectedProductMenu">Menu: {{ selectedProductMenu.listName }}</span>
                  </div>
                  <div class="img-wrp">
                      <img *ngIf="!product.image" src="../../../../../../assets/images/pro-img.svg" alt="">
                      <advanced-image *ngIf="product?.image" [cldImg]="product?.image"></advanced-image>
                  </div>
                </div>
                <div class="right menu-btn">
                  <app-actions-dropdown
                    [actions]="product | streamProductActions : productsExhausted : startStream : timeLeft : gproductsExhausted"
                    triggerColor="black"
                    (action)="onProductAction($event, product)"
                  ></app-actions-dropdown>
                </div>
              </div>
            </div>
            <button class="action-btn border-btn" (click)="openProductDialog(productSellTypes.AUCTION)">+ Create Product</button>
          </div>
        </mat-tab>
        <mat-tab label="Buy Now">
          <div class="search">
            <input type="text" placeholder="Search products..." (keyup)="searchProduct($event)" />
          </div>
          <div class="purchased-list leftdata-list">
            <div class="product-count" *ngIf="!buynowProducts.length; else buynowProductsHeader">No buy now product available</div>
            <ng-template #buynowProductsHeader>
              <div class="product-count"> {{ buynowProducts.length }} {{ buynowProducts.length > 1 ? "Products" : "Product" }}</div>
            </ng-template>
            <div class="product-list">
              <div class="flex flex-sb list-data" *ngFor="let product of buynowProducts; trackBy: trackByProducts">
               <div class="left product-left-wrp">
                <div class="inner-wrp flex column">
                  <strong>{{product.name}} @ ${{product.price}} / pc</strong>
                  <span>{{product.quantity}} Available</span>
                  <span *ngIf="product.streamUuid !== loadedStreamData?.uuid && selectedProductMenu">Menu: {{ selectedProductMenu.listName }}</span>
                </div>
                <div class="img-wrp">
                  <img *ngIf="!product.image" src="../../../../../../assets/images/pro-img.svg" alt="">
                  <advanced-image *ngIf="product?.image" [cldImg]="product?.image"></advanced-image>
              </div>
               </div>
                <div class="right menu-btn">
                  <app-actions-dropdown
                    [actions]="product | streamProductActions : productsExhausted : startStream : timeLeft : gproductsExhausted"
                    triggerColor="black"
                    (action)="onProductAction($event, product)"
                  ></app-actions-dropdown>
                </div>
              </div>
            </div>
            <button class="action-btn border-btn" (click)="openProductDialog(productSellTypes.BUY_NOW)">+ Create Product</button>
          </div>
        </mat-tab>
        <mat-tab label="Giveaway">
          <div class="search">
            <input type="text" placeholder="Search products..." (keyup)="searchGiveawayProducts($event)" />
          </div>
          <div class="purchased-list leftdata-list">
            <div class="product-count" *ngIf="!giveawayProducts.length; else giveawayProductsHeader"> No giveaway product available</div>
            <ng-template #giveawayProductsHeader>
              <div class="product-count"> {{ giveawayProducts.length }} {{ giveawayProducts.length > 1 ? "Products" : "Product" }}</div>
            </ng-template>
            <div class="product-list">
              <div class="flex flex-sb list-data" *ngFor="let product of giveawayProducts; trackBy: trackByProducts">
               
                <div class="left product-left-wrp">
                  <div class="inner-wrp flex column">
                    <strong>{{product.name}} @ ${{product.price}} / pc</strong>
                    <span>{{product.quantity}} Available</span>
                    <span *ngIf="product.streamUuid !== loadedStreamData?.uuid && selectedProductMenu">Menu: {{ selectedProductMenu.listName }}</span>
                  </div>
                  <div class="img-wrp">
                    <img *ngIf="!product.image" src="../../../../../../assets/images/pro-img.svg" alt="">
                    <advanced-image *ngIf="product?.image" [cldImg]="product?.image"></advanced-image>
                </div>
                </div>
               
                <div class="right menu-btn">
                  <app-actions-dropdown
                    [actions]="product | streamProductActions : productsExhausted : startStream : timeLeft : gproductsExhausted"
                    triggerColor="black"
                    (action)="onProductAction($event, product)"
                  ></app-actions-dropdown>
                </div>
              </div>
            </div>
            <button class="action-btn border-btn" (click)="openProductDialog(productSellTypes.GIVEAWAY)">+ Create Product</button>
          </div>
        </mat-tab>
        <mat-tab label="Sold">
          <div class="search">
            <input type="text" placeholder="Search products..." (keyup)="searchSoldProduct($event)" />
          </div>
          <div class="purchased-list leftdata-list">
            <div class="product-count" *ngIf="soldProducts.length === 0">No sold product available</div>
            <div class="product-count" *ngIf="soldProducts.length === 1"> {{ soldProducts.length }} Product</div>
            <div class="product-count" *ngIf="soldProducts.length > 1"> {{ soldProducts.length }} Products</div>
            <div class="product-list">
              <div class="flex flex-sb list-data" *ngFor="let product of soldProducts; trackBy: trackByProducts">
               
                <div class="left product-left-wrp">
                  <div class="inner-wrp flex column">
                    <strong>{{product.name}} @ ${{product.price}} / pc</strong>
                    <span>{{product.quantity}} sold</span>
                  </div>
                  <div class="img-wrp">
                    <img *ngIf="!product.image" src="../../../../../../assets/images/pro-img.svg" alt="">
                    <advanced-image
                      *ngIf="product?.image"
                      [cldImg]="product | imageDisplay : 64 : 64"
                    ></advanced-image>
                  </div>
                </div>
              </div>
            </div>
            <button class="action-btn border-btn" (click)="openProductDialog(productSellTypes.AUCTION)">+ Create Product</button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="streaming-live disable">
    <div class="streaming-left">
      <div class="stream-image-video">
        <div id="stream-div" class="stream-div">
          <div class="head-section" *ngIf="auctionData">
            <div class="left-part">
              <div class="title-heading text-ellipsis">
                <h2 title="{{auctionData.name}}">
                  {{auctionData?.name}}
                </h2>
              </div>
              <div
                class="title text-ellipsis"
                title="{{auctionData.description}}"
              >
                {{auctionDescription}}
              </div>
            </div>
            <div class="right-part">
              <div class="live-count"> <span class="count-icon"></span> {{memberCount}}</div>
            </div>
            <ng-container *ngIf="!startStream">
              <advanced-image [cldImg]="streamPreviewImage" [plugins]="plugins" class="inner-image"></advanced-image>
              <div class="schedule-time">
              <div class="hed-title">Stream is scheduled for 
                {{streamDetails.scheduleDate}}(PST)</div>
                  <div class="schedule-title">
                    Please click on start streaming 
                  </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="inner-wrapper">
        <div class="stream-footer flex flex-center space-between">
          <div class="auction-end">
            <button class="primary-btn disable" style="cursor: none;" *ngIf="timeLeft == 0 && startStream">
              Auction Ended
            </button>
            <button class="primary-btn winner" *ngIf="ProductPinned == 1 && startStream" (click) = "selectWinner()">
              Select Winner
            </button>
            <div class="label-wrap" *ngIf="timeLeft > 0">
              <label class="time-label">
                Time Left : {{timeLeftString}}
              </label>
              <br />
              <label class="time-label"> 
                Bid Amt : ${{bidAmount}}
              </label>
            </div>
            <button class="primary-btn endStream-btn" *ngIf="timeLeft <= 0 && startStream && !agoraReachedFreeTrialLimit" (click)="stopStream()">
              End Streaming
            </button>
          </div>
        </div>
        <div class="video-icon" *ngIf="!agoraReachedFreeTrialLimit">
          <img class="icon-1" src="../../../../../../assets/images/streaming-1.svg" alt="" *ngIf="!isMobile" (click)="activeInMobile()">
          <img class="icon-1" src="../../../../../../assets/images/streaming-1.svg" alt="" *ngIf="isMobile" (click)="inActiveInMobile()">
          <img *ngIf="!isMuted && startStream" src="../../../../../../assets/images/unmute.svg" (click)="muteOrUnmute()" />
          <img *ngIf="isMuted && startStream" src="../../../../../../assets/images/mute.svg" (click)="muteOrUnmute()" />
          <img *ngIf="isCameraOn && startStream" src="../../../../../../assets/images/cameraon.svg" (click)="cameraOnOff()" />
          <img *ngIf="!isCameraOn && startStream" src="../../../../../../assets/images/cameraoff.svg" (click)="cameraOnOff()" />
          <img *ngIf="startStream" class="icon-5" src="../../../../../../assets/images/share.svg" (click)="socialMedia()" />
        </div>
      </div>
    </div>
    <div class="streaming-right">
      <div #scrollMe class="chat-wrap">
          <div class="chat-inner-wrap flex column justify-right">
            <div   class="flex flex-center chat" *ngFor="let msg of messageData;">
                <div class="chat-img br50">
                  <img *ngIf="msg.userId === 'HOST' && !urlImage" src="../../../../../../assets/imgs/default-profile.png">
                  <advanced-image [cldImg]="urlImage" *ngIf="msg.userId === 'HOST' && urlImage"></advanced-image>
                  <img *ngIf="msg.userId != 'HOST' && !msg.image" src="../../../../../../assets/imgs/default-profile.png" />
                  <advanced-image [cldImg]="msg.image" *ngIf="msg.userId != 'HOST' && msg.image"></advanced-image>
                </div>
                <div class="chat-text-wrap">
                    <div class="name cursor" *ngIf="msg.userId != 'HOST'" (click)="displayBlockedUsers(msg)">
                      {{msg.userId}}
                      <span class="moderator" *ngIf="moderatorIds && moderatorIds.includes(msg.id)">
                        Moderator
                      </span>
                    </div>
                    <div class="name" *ngIf="msg.userId == 'HOST'">
                      {{msg.userId}}
                      <span class="moderator">
                        Host
                      </span>
                    </div>
                    <div
                      class="chat"
                      [ngClass]="{'block-text': msg.message === 'Blocked by host' ||  msg.message == 'Blocked by moderator'}"
                    >
                      {{msg.message}}
                    </div>                    
                </div>
            </div>
        </div>
          <div class="input-chat flex d-flex flex-center flex-ce">
            <input [(ngModel)]="chatInput" type="text" placeholder="Say something..." (keydown.enter)="submitMessage()" />
            <img src="../../../../../../assets/images/chat.svg" alt="" (click)="submitMessage()">
          </div>
        </div>
    </div>
  </div>
</div>