import { Pipe, PipeTransform } from '@angular/core';
import { IStreamProduct } from '../interfaces';
import { IActionDropdownOption } from '../../../../shared/components/actions-dropdown/interfaces';
import { ProductSellType } from '../../../../shared/interfaces/interface';

@Pipe({ name: 'streamProductActions' })
export class StreamProductActionsPipe implements PipeTransform {

  transform(
    product: IStreamProduct,
    productsExhausted: number[],
    startStream: boolean,
    timeLeft: number,
    gproductsExhausted: number[]
): IActionDropdownOption[] {
    if (product) {
        const actions: IActionDropdownOption[] = [
            {
                icon: 'edit',
                title: 'Edit Product',
                actionType: 'edit'
            },
            {
                icon: 'delete',
                title: product.stream_product_id ? "Delete" : "Remove",
                actionType: 'delete'
            }
        ];

        const { sell_type } = product;

        if([ProductSellType.AUCTION, ProductSellType.GIVEAWAY].includes(sell_type)) {
            let isDisabled = false;

            if (sell_type === ProductSellType.AUCTION) {
                isDisabled = !(!productsExhausted.includes(product.stream_product_id) && startStream)
            } else {
                isDisabled = timeLeft > 0 || (!startStream) || gproductsExhausted.includes(product.product_id)
            }

            actions.unshift({
                icon: 'shop',
                title: product.sell_type === ProductSellType.AUCTION ? "Start Auction" : "Pin Product",
                actionType: 'shop',
                disabled: isDisabled
            })
        }

        return actions;
    }
    
    return [];
  }
}