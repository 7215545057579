import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-stream-error',
  templateUrl: './stream-error.component.html',
  styleUrls: ['./stream-error.component.scss']
})

export class StreamErrorComponent implements OnInit {
  private _message: string;

  public get message() {
    return this._message;
  }

  constructor(
    public dialogRef: MatDialogRef<StreamErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {reason: 'video' | 'audio' | 'stream'}
  ) {}

  ngOnInit(): void {
    this._message = this.getErrorMessage(this.data.reason);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  reload(): void {
    window.location.reload();
  }

  private getErrorMessage(reason: string): string {
    switch(reason) {
      case 'audio':
        return 'Failed to access microphone.\nPlease check your microphone settings and try again.';
      case 'video':
        return 'Failed to access camera.\nPlease check your camera settings and try again.';
      case 'stream':
        return 'Failed to publish the stream.\nPlease check your connection and try again.';
      default:
        return 'An unknown error occurred.\nPlease try again.';
    }
  }
}