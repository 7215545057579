import {Component,ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { AddProductComponent } from '../../../../shared/components/add-product/add-product.component';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { StreamApiService } from '../../../../../core/stream/stream.service';
import { CreateAuctionComponent } from '../create-auction/create-auction.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { StreamErrorComponent } from '../stream-error/stream-error.component';
import { Cloudinary, CloudinaryImage } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { StreamSandbox } from '../../../../../core/stream/stream.sandbox';
import { SocialmediaComponent } from '../socialmedia/socialmedia.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import {GivewayWinnerComponent} from '../giveway-winner/giveway-winner.component'
import { accessibility, responsive } from '@cloudinary/ng';
import { BlockedUsersModalComponent } from '../blocked-users-modal/blocked-users-modal.component';
import { LocationStrategy } from '@angular/common';
import { io, Socket } from 'socket.io-client';
import { ICategory, IProduct, IStream, ProductSellType } from '../../../../shared/interfaces/interface';
import { ProductsService } from '../../../../shared/services/products.service';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap} from 'rxjs/operators';
import { IStreamProduct } from '../interfaces';
import { ProductMenusService } from '../../our-products/product-menus/services/product-menus.service';
import { IProductMenu } from '../../our-products/product-menus/interfaces';
import { ConfirmDeleteProductFromList } from '../../../../shared/components/confirm-delete-product-from-list/confirm-delete-product-from-list.component';
import { SocketEventsEnum } from '../../../../shared/enums/socket-events';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IActionDropdownOption } from '../../../../shared/components/actions-dropdown/interfaces';

@Component({
  selector: 'app-scheduled-stream',
  templateUrl: './scheduled-stream.component.html',
  styleUrls: ['./scheduled-stream.component.scss']
})

export class ScheduledStreamComponent implements OnInit, OnDestroy  {
  loadedStreamData: IStream;
  productSellTypes = ProductSellType;
  agoraReachedFreeTrialLimit = false;

  selectedCategory: ICategory;
  dropdownCategories: {value: ICategory, label: string}[];
  avatar: CloudinaryImage;

  selectedProductMenu: IProductMenu;
  selectedProductsBeforeOpen: {value: IStreamProduct, label: string}[];
  selectedProducts: {value: IStreamProduct, label: string}[] = [];
  selectedProductsChanged = false;
  dropdownProductMenus: {value: IProductMenu, label: string}[];
  dropdownProducts: {value: IProduct, label: string}[] = [];
  public imageUrl = environment.imageUrl;
  public cloudName = environment.cloudName;
  public cloudFolder = environment.cloudFolder;
  startStream: boolean = false;
  options: any = {
    appID: '', //environment.appId, //"5b5f268ce79647a0bf02a10a653b2917", // "922a7f918e44480ab0feac5367e638ab",
    channel: "POKEMON",
    host: "HOST",
    audience: "guest",
    token : "",
    messageChannel: ""
  };
  streamDetails: any = {};
  rtc: any = {
    client: null,
    localAudioTrack: null,
    localVideoTrack: null,
    joined: false,
    published: false,
    localStream: null,
    remoteStream: [],
    params: {},
  }
  client : any = null;
  channel : any = null;
  messageData = [];

  allAuctionProducts: IStreamProduct[] = [];
  auctionProducts: IStreamProduct[] = [];

  allBuyNowProducts: IStreamProduct[] = [];
  buynowProducts: IStreamProduct[] = [];

  allGiveawayProducts: IStreamProduct[] = [];
  giveawayProducts: IStreamProduct[] = [];

  allSoldProducts: IProduct[] = [];
  soldProducts: IProduct[] = [];

  isStreamOn: boolean = false;
  chatInput: string = '';  
  isMuted: boolean = false;
  isCameraOn: boolean = true;
  timeLeft: number = -1;
  timeLeftString: string = '';
  bidAmount: number = 0;
  ProductPinned : number = 0;
  interval;
  auctionData: any;
  auctionDescription = '';
  //isAddress : boolean = false;
  productIdWinner: number;
  cld = new Cloudinary({
    cloud: {
      cloudName: this.cloudName,
    }
  });
  productsExhausted: number[] = [];
  streamPreviewImage: CloudinaryImage;
  plugins = [responsive(), accessibility()];
  memberCount: number = 0;
  public userDetails;
  urlImage : any;
  sendImage : any;
  isMobile : boolean = true;
  senderImage : any;
  selectingWinner: boolean = false;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  public auctionProduct: number;
  moderatorIds: number[] = [];
  socket: Socket;
  gproductsExhausted: number[] = [];
  streamListId: number;

  constructor(
    public dialog: MatDialog, 
    public service: StreamApiService, 
    public toastr: ToastrService,
    private router: Router, 
    public streamSandbox: StreamSandbox, 
    private deviceService: DeviceDetectorService,
    public productsService: ProductsService,
    private location: LocationStrategy,
    private productsMenusService: ProductMenusService
  ) {
      this.userDetails = JSON.parse(localStorage.getItem('vendorUserDetails'));
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
      this.socket = io(environment.chatUrl, {
        transports: ['websocket'],
      });
  }

  ngOnInit() {
    window.addEventListener("keyup", this.disableF5);
    window.addEventListener("keydown", this.disableF5);
  
    //window.addEventListener("beforeunload", this.onConfirmRefresh, { capture: true });
    
    try {
      if(this.userDetails.avatarPath && this.userDetails.avatar) {
        this.urlImage = this.cld.image(this.cloudFolder + "/"+ this.userDetails.avatarPath + "/" + this.userDetails.avatar);
        this.urlImage.resize(fill().width(88).height(125)).border(byRadius(8));
        this.sendImage = this.userDetails.avatarPath + "/" + this.userDetails.avatar;
      }
    } 
    catch(e){ 
      console.log(e); 
    }

    this.streamDetails = JSON.parse(localStorage.getItem('currentStreamDetails'));  
    this.getProductMenus();
    this.getDropdownProducts();
    if (localStorage.getItem('clickedLink') === 'start') {
      const asideBarElement = document.getElementsByClassName('aside-bar')[0] as HTMLElement;
      if(asideBarElement) { asideBarElement.parentElement.style.display = 'none'; }
      
      const pageElement = document.getElementsByClassName('page-wrapper')[0] as HTMLElement;
      if(pageElement) { pageElement.style.width = '100%'; }
      
      this.startStream = true;
      this.options.channel = JSON.parse(localStorage.getItem('vendorUser')).streamChannel;

      forkJoin([
        this.service.getStream({streamUuid: this.streamDetails.uuid}),
        this.getCategories()
      ]).subscribe(([data, categories]: [{data: IStream}, ICategory[]]) => {
        if (data) {
          const stream = data.data;
          this.dropdownCategories = categories.filter(category => !!category.parentInt).map(category => ({value: category, label: category.name}));
          this.selectedCategory = this.dropdownCategories.find(category => category.value.categoryId === data.data.subCategory_id)?.value;
          this.avatar = this.cld.image(this.cloudFolder + "/"+ stream.vendorDetails.avatar_path + "/" + stream.vendorDetails.avatar);
          this.avatar.resize(fill().width(40).height(40)).roundCorners(byRadius(20));
          this.streamListId = stream?.listId;
          this.loadedStreamData = stream;
          this.options.appID = stream?.appId;
          this.moderatorIds = stream?.moderator_id;  
          this.options.channel = stream?.vendorDetails?.stream_channel;
          this.options.messageChannel = stream?.vendorDetails?.message_channel;
          this.rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });

          this.getStreamProducts();
        }
      });

      this.getOldChangeMessages();
    } else {
      if(this.deviceService.isMobile() || this.deviceService.isTablet()) {
        this.isMobile = true;
        const hgt = window.innerHeight + 40;
        const wd = Math.floor(window.innerWidth);
        this.streamPreviewImage = this.cld.image(this.streamDetails.preview_image.publicID);
        this.streamPreviewImage.resize(fill().width(wd).height(hgt));
      } else {
        this.isMobile = false;
        const hgt = window.innerHeight + 40;
        const wd = Math.floor(window.innerWidth - (66.6 * window.innerWidth / 100));
        this.streamPreviewImage = this.cld.image(this.streamDetails.preview_image.publicID);
        this.streamPreviewImage.resize(fill().width(wd).height(hgt));
      }
      this.getStreamProducts();
      this.getOldChangeMessages();
    }
    
    if (this.startStream) {
      this.socket.emit(SocketEventsEnum.JOIN_ROOM, this.streamDetails.uuid, this.userDetails?.id);

      this.socket.on(SocketEventsEnum.GROUP_MESSAGE_RECEIVE, ({ id, message, profileUrl, roomId, userId }) => {
        if(message && message != '') {
          let messageObject = {
            message,
            id: id,
            userId: userId,
            profileUrl: profileUrl,
            roomId: roomId
          };

          if(profileUrl) {
            this.senderImage = this.cld.image(this.cloudFolder + "/"+ profileUrl);
            this.senderImage.resize(fill().width(36).height(36)).border(byRadius(18));
          } else {
            this.senderImage = '';
          }

          messageObject['image'] = this.senderImage;

          this.messageData.push(messageObject);
          this.displayUserCount();
        }
      });

      this.socket.on(`${this.streamDetails.uuid}-${SocketEventsEnum.BID}`, (data) => {
        if (data) {
          const dateSent = new Date(data.endTime.replace(' ', 'T') + 'Z');
          const currentDate = new Date(data.currentTime.replace(' ', 'T') + 'Z');
          const seconds = Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours(), dateSent.getMinutes(), dateSent.getSeconds()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds())) /(1000));
          this.timeLeft = seconds;
          this.timeLeftString = Math.floor((seconds / 60)) + "m " + (seconds % 60) + "s";
          this.bidAmount = data.bidAmount;
          this.toastr.success("Bid from: "+ data.customer.firstName + ' ' + data.customer.lastName);
        }
      });

      this.socket.on(`${this.streamDetails.uuid}-${SocketEventsEnum.WIN}`, (data) => {
        if (data && !this.selectingWinner) {
          alert('Person with username : ' + data.name + ' has won the auction with bid amount: $' + data.bidAmount);
        }
      });

      this.socket.on(`${this.streamDetails.uuid}-${SocketEventsEnum.STREAM_NOTIFICATIONS}`, (data) => {
        if (data.type === 'stream-updated') {
          const streamData = data.streamData;

          if (streamData && streamData.subCategory_id !== this.selectedCategory.categoryId) {
            const newCategory = this.dropdownCategories.find(category => category.value.categoryId === streamData.subCategory_id)?.value;

            if (newCategory) {
              this.selectedCategory = newCategory;
            }
          }
        }
      })
    }

    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.isMobile = false;
    }
  }

  openProductDialog(sellType: ProductSellType, productId?: number): void {
    // this.isAddress  = JSON.parse(localStorage.getItem('vendorUserDetails')).isAddress;
    // if(this.isAddress) {
      this.dialog.open(AddProductComponent, {
          data: {
            allowedSellTypes: [
              ProductSellType.AUCTION,
              ProductSellType.BUY_NOW,
              ProductSellType.GIVEAWAY
            ],
            sellType, 
            uuid : this.streamDetails.uuid,
            product_id : productId,
            isStreamProduct: true,
            closeAfterSubmit: true
          },
        })
        .afterClosed()
        .subscribe(({product, isCancel}) => {
          if (product && !productId) {
            this.dropdownProducts = [
              {
                value: {
                  ...product,
                  product_id: product.productId
                },
                label: product.name
              },
              ...this.dropdownProducts
            ];
          }

          if (!isCancel) {
            this.getStreamProducts(false);
          }
        }
      );
    // }
    // if(!this.isAddress) {
    //   this.dialog.open(AddressmodalComponent);
    // }
  }

  startAuction(product: IStreamProduct, stream: IStream) {
    if (product.stream_product_id) {
      this.auctionProduct = product.stream_product_id;
      this.onStartAuction(product, stream);
    } else {
      this.attachProductToStream(product).subscribe((productResult) => {
        this.auctionProduct = productResult.stream_product_id;
        this.onStartAuction(productResult, stream);
      })
    }
  }

  onStartAuction(product: IStreamProduct, stream: IStream): void {
    this.dialog.open(CreateAuctionComponent, {
      data: {
        productDetails: product,
        streamDetails: stream
      }
    }).afterClosed().subscribe((data) => {
      if (data) {
        this.timeLeft = data.time;
        this.bidAmount = data.price;
        this.auctionData = data;
        this.auctionDescription = this.getAuctionDescription(product.description);
        this.startTimer();
        this.productsExhausted.push(data.stream_product_id);
      }
    });
  }

  displayBlockedUsers(messageData : any)  {
    if(messageData.id){
      this.dialog.open(BlockedUsersModalComponent, {
        data: {
          uuid : this.streamDetails.uuid,
          userId: messageData.id
        }
      })
    }
  }

  private pinProduct(product: IStreamProduct): void {
    if (product.stream_product_id) {
      this.productIdWinner = product.product_id;
      this.onPinProduct(product);
    } else {
      this.attachProductToStream(product).subscribe((productResult) => {
        this.productIdWinner = product.product_id;
        this.onPinProduct(productResult);
      });
    }
  }

  private attachProductToStream(product: IStreamProduct): Observable<IStreamProduct> {
    return this.service.updateProduct(
      {
        streamUuid: this.loadedStreamData.uuid
      },
      product.product_id
    ).pipe(
      switchMap(() => this.getProducts(product.sell_type)),
      map((products: IStreamProduct[]) => {
        return products.find(productItem => productItem.product_id === product.product_id);
      })
    )
  }

  private onPinProduct(product: IStreamProduct): void {
    this.service.pinProduct({productId: product.product_id})
      .subscribe((data) => {
        if (data) {
          this.ProductPinned = 1;
          this.auctionData = product;
          this.auctionDescription = this.getAuctionDescription(product.description);
          this.gproductsExhausted.push(product.product_id);
        }
      });
  }

  private getAuctionDescription(htmlDescription: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlDescription;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  selectWinner() {
    const params: any = {};
    this.selectingWinner = true 
    params['productId'] = this.productIdWinner;
    this.service.selectWinner(params).subscribe((data) => {
      if(data.status == 0) {
        this.toastr.error(data.message)
      } else {
        this.ProductPinned = 0
        this.dialog.open(GivewayWinnerComponent, {
          data: {
            userDetails: data.data,
          }
        }).afterClosed()
        .subscribe(() => {
          this.selectingWinner = false;
          this.filterProducts(ProductSellType.GIVEAWAY, this.productIdWinner);
          this.filterSelectedProductsInDropdown(this.productIdWinner);
          this.getSoldProducts();
        })
      }
    })
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
  }

  disableF5(e) {
    if ((e.which || e.keyCode) == 116) e.preventDefault(); 
  }

  onConfirmRefresh (event) {
    event.preventDefault();
    event.returnValue = '';
  }

  getRtcToken = (uid, role, tokenType) => {
    const url = `/stream/getStreamToken?token=RTC&channel=${this.options.channel}&role=${role}&tokentype=${tokenType}&uid=${uid}`;
    this.service.getToken(url).subscribe((data) => {
      if (data) {
        this.joinChannelAsHost(data.rtcToken);
      }
    });
  }

  joinChannelAsHost = async (token) => {
    try {
      this.rtc.client.setClientRole('host');
     
      try {
        this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        this.rtc.localAudioTrack.setVolume(100)
      } catch {
        return this.showErrorModal('audio');
      }

      try {
        this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      } catch {
        return this.showErrorModal('video');
      }

      await this.rtc.client.join(this.options.appID, this.options.channel, token, this.options.host);
      
      try {
        this.rtc.localStream = await this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
      } catch {
        return this.showErrorModal('stream');
      }

      this.rtc.localVideoTrack.play('stream-div');

      this.updateStreamStatusToStart(token);
    } catch (error) {
      if (error?.code === 'CAN_NOT_GET_GATEWAY_SERVER') {
        this.onAgoraReachedFreeTrialLimit();
      } else {
        this.showErrorModal('');
      }
    }
  }

  private updateStreamStatusToStart(token): void {
    const params = { token };
    this.service.updateStreamStatus(this.streamDetails.uuid, 1, params).subscribe(data => {
      if(data && data.data) {
        if (data.data.sID === 0) {
          console.warn("sID is not set", data.data);
        }

        if (!sessionStorage.getItem('cloud-data')){
          sessionStorage.setItem('cloud-data', JSON.stringify(data.data));
        }
      }
    });
  }

  private onAgoraReachedFreeTrialLimit(): void {
    this.agoraReachedFreeTrialLimit = true;
    this.mute();
    this.isCameraOn = false;
    this.rtc.localVideoTrack.setEnabled(false);
    this.stopAgora();
  }

  private showErrorModal(reason: 'video' | 'audio' | 'stream' | ''): void {
    this.dialog.open(StreamErrorComponent, {data: {reason}});
  }

  searchAuctionProduct(event) {
    this.auctionProducts = this.allAuctionProducts.filter(p => p.name.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  searchProduct(event) {
    this.buynowProducts = this.allBuyNowProducts.filter(p => p.name.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  searchGiveawayProducts(event) {
    this.giveawayProducts = this.allGiveawayProducts.filter(p => p.name.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  searchSoldProduct(event) {
    this.soldProducts = this.allSoldProducts.filter(p => p.name.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  private deleteProduct(product: IStreamProduct) {
    if (product.stream_product_id) {
      let url = '/stream/deleteStreamProduct?streamuuid=' + this.streamDetails.uuid + '&productID=' + product.product_id;
      this.service.deleteProduct(url).subscribe(async (data) => {
        if (data?.status === 1) {
          this.getStreamProducts();
        } else {
          this.toastr.error(data.message);
        }
      });
    } else {
      this.dialog.open(ConfirmDeleteProductFromList, {
        data: {
          menu: this.selectedProductMenu,
          product 
        }
      }).afterClosed().subscribe((deletedProductId) => {
        if (deletedProductId) {
          this.filterProducts(product.sell_type, product.product_id);
          this.filterSelectedProductsInDropdown(product.product_id);
        }
      });
    }
  }

  private filterProducts(productSellType: ProductSellType, deletedProductId: number): void {
    switch(productSellType) {
      case ProductSellType.AUCTION:
        this.allAuctionProducts = this.allAuctionProducts.filter(productItem => productItem.product_id !== deletedProductId);
        this.auctionProducts = this.auctionProducts.filter(productItem => productItem.product_id !== deletedProductId);
        return;
      case ProductSellType.BUY_NOW:
        this.allBuyNowProducts = this.allBuyNowProducts.filter(productItem => productItem.product_id !== deletedProductId);
        this.buynowProducts = this.buynowProducts.filter(productItem => productItem.product_id !== deletedProductId);
        return;
      case ProductSellType.GIVEAWAY:
        this.allGiveawayProducts = this.allGiveawayProducts.filter(productItem => productItem.product_id !== deletedProductId);
        this.giveawayProducts = this.giveawayProducts.filter(productItem => productItem.product_id !== deletedProductId);
        return;
    }
  }

  private editProduct(sellType: ProductSellType, product: IStreamProduct): void {
    this.openProductDialog(sellType, product.product_id);
  }

  submitMessage() {
      const msg = this.chatInput;
      this.chatInput = '';
      
      const messageObject = {
        message: msg,
        id: this.userDetails?.id,
        userId: "HOST",
        profileUrl: this.sendImage || '',
        roomId: this.streamDetails.uuid,
      };

      this.socket.emit(
        SocketEventsEnum.GROUP_MESSAGE_SEND,
        this.streamDetails.uuid,
        messageObject
      );

      this.service.pushChatMessage(messageObject).subscribe();
      messageObject['email'] = this.userDetails?.email;
      this.messageData.push(messageObject);
      this.scrollToBottom();
  }

  async ngOnDestroy() {
    if(this.startStream) {
      await this.channel.leave();
      await this.client.logout();
    }
    this.pauseTimer();
  }

  startTimer(): void {
    this.pauseTimer();
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
        this.timeLeftString = Math.floor((this.timeLeft / 60)) + "m " + (this.timeLeft % 60) + "s";
      } else if (this.timeLeft === 0) {
        this.callAuctionEnd();
        this.pauseTimer();
      }
    }, 1000)
  }

  private pauseTimer(): void {
    clearInterval(this.interval);
  }

  private callAuctionEnd(): void {
    this.service.auctionEnd(this.auctionData.auctionId).pipe(
      catchError(error => {
        //this.filterProducts(this.auctionData.sell_type, this.auctionData.product_id);
        return EMPTY;
      })
    ).subscribe((data) => {
      if (data?.status === 1) {
        this.toastr.success(data.message);
        this.filterProducts(this.auctionData.sell_type, this.auctionData.product_id);
      } else if (data?.message === 'No Bidder Found') {
        this.toastr.error(data.message);
        this.removeProductFromExhausted(this.auctionProduct);
      }
    });
  }

  private removeProductFromExhausted(productId: number): void {
    this.productsExhausted = this.productsExhausted.filter(id => id !== this.auctionProduct);
  }

  async stopStream() {
    if (confirm('Are you sure you want to end the stream ?')) {
      await this.stopAgora();

      const cloudData = JSON.parse(sessionStorage.getItem('cloud-data'));
        let params = {
          token: cloudData.authtoken,
          cloudResourceid: cloudData.cloudResourceid,
          sID: cloudData.sID
        };
        this.service.updateStreamStatus(this.streamDetails.uuid, 2, params).subscribe(data => {
          if(data) {
            sessionStorage.removeItem('cloud-data');
            this.toastr.success("Stream stopped successfully");
            this.router.navigate(['/stream/my-live-stream']);
          }
        });
    }
  }

  private async stopAgora() {
    try {    
      if(this.startStream) {
        if(this.rtc.localAudioTrack && this.rtc.localVideoTrack) {
          await this.rtc.client.unpublish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
          await this.channel.leave();
          await this.client.logout();
        }
      }
    } 
    catch (e) {
      if(this.startStream) {
        try {
          await this.channel.leave();
          await this.client.logout();
        } 
        catch(e) {}
      }
    }
  }

  mute(){
    this.rtc.localAudioTrack.setEnabled(false);
  }

  unmute(){
    this.rtc.localAudioTrack.setEnabled(true);
  }

  muteOrUnmute(){
    if(this.isMuted) {
      this.isMuted = false;
      if (this.startStream) {
        this.unmute();
      }
    }
    else{
      this.isMuted = true;
      if (this.startStream) {
        this.mute();
      }
    }
  }

  cameraOnOff() {
    if (this.isCameraOn) {
      this.isCameraOn = false;
      this.rtc.localVideoTrack.setEnabled(false);
    }
    else {
      this.isCameraOn = true;
      this.rtc.localVideoTrack.setEnabled(true);
    }
  }

  back() {
    if (this.startStream) {
      this.stopStream();
    } else {
      this.router.navigate(['/stream/my-live-stream']);
    }
  }

  onTabChanged(event: MatTabChangeEvent): void {
    if (event.tab?.textLabel === 'Sold') {
      this.getSoldProducts();
    }
  }

  private getSoldProducts() {
    this.service.getSoldProducts(this.streamDetails.uuid).subscribe(data => {
      if (data?.status == 1) {
        this.allSoldProducts = data.data.products;
        this.soldProducts = data.data.products;
      }
    });
  }

  /**
   * @method -- open socialmedia modal
   */
   socialMedia() {
    this.dialog.open(SocialmediaComponent, {
      data: {
        id : this.streamDetails.id,
        uuid : this.streamDetails.uuid
      }
    }).afterClosed().subscribe(() => {
      
    });
  }

  /**
   * @method -- set Active Class
   * @param event 
   */
  setActiveClass(event) {
    var collection = document.getElementsByClassName('list-data');
      for (let i = 0; i < collection.length; i++) {
        collection[i].classList.remove('active');
      }
    var element = event.currentTarget.parentElement.parentElement.parentElement.classList.add('active');
  }

  /**
   * @method -- Active for mobile
   */
  activeInMobile() {
    this.isMobile = true;
  }

  /**
   * @method -- In Active for mobile
   */
  inActiveInMobile() {
    this.isMobile = false;
  }

  displayUserCount() {
    this.memberCount = this.messageData.map((item) => item.id).filter((value, index, current_value) => 
      current_value.indexOf(value) === index
    ).length;
  }

  onProductMenuChange({value}: {value: IProductMenu}): void {
    if (this.streamDetails) {
      const listId = value?.productListId || null;
      this.updateStream({listId}).subscribe(res => {
        if (res?.status === 1) {
          this.streamListId = listId;
          this.getStreamProducts();
        }
      });
    }
  }

  updateStream(params): Observable<any> {
    if (this.streamDetails) {
      const {
        title, description,
        isExplicit,
        category_id, subCategory_id, scheduleDate, scheduletime,
        moderator, image_path, uuid, scheduletimeStamp
      } = this.streamDetails;

      const paramsResult = {
        title,
        description,
        isExplicit,
        category_id,
        subCategory_id,
        scheduleDate: scheduleDate?.split(',')[0],
        scheduletime,
        path: image_path,
        preview_image: this.loadedStreamData.preview_image,
        scheduledDateTime: Number(scheduletimeStamp),
        moderator: JSON.parse(moderator),
        ...params
      };

      return this.service.updateStream(paramsResult, uuid);
    }
  }

  onProductsDropdownOpen(): void {
    this.selectedProductsChanged = false;
    this.selectedProductsBeforeOpen = [...this.selectedProducts];
  }

  onProductsDropdownClose(): void {
    if (this.selectedProductsChanged) {

      const prevSelectedIds = new Set(this.selectedProductsBeforeOpen.map(option => option.value.product_id));
      const newSelectedIds = new Set(this.selectedProducts.map(option => option.value.product_id));
      const addedIds = [...newSelectedIds].filter(id => !prevSelectedIds.has(id));
      const removedIds = [...prevSelectedIds].filter(id => !newSelectedIds.has(id));

      forkJoin([
        ...addedIds.map(id => this.service.updateProduct({ streamUuid: this.loadedStreamData.uuid}, id)),
        ...removedIds.map(id => this.service.updateProduct({streamUuid: null}, id))
      ]).subscribe(() => {
        this.getStreamProducts(false);
      })
    }
  }

  onStreamCategoryChange({value}: {value: ICategory}) {
    this.updateStream({
      subCategory_id: value.categoryId,
      category_id: value.parentInt
    }).subscribe(res => {
      if (res.status === 1) {
        this.toastr.success('Stream category has been updated');
        this.selectedCategory = value;
      }
    });
  }

  onProductAction(action: IActionDropdownOption, product: IStreamProduct): void {
    const { actionType } = action;
    const { sell_type } = product;
    
    switch(actionType) {
      case 'edit':
        this.editProduct(sell_type, product);
        break;
      case 'delete':
        this.deleteProduct(product);
        break;
      case 'shop':
        if (sell_type === ProductSellType.AUCTION) {
          this.startAuction(product, this.streamDetails)
        } else if (sell_type === ProductSellType.GIVEAWAY) {
          this.pinProduct(product);
        }
        break;
    }
  }
  

  trackByProducts(product: IStreamProduct): number {
    return product.product_id;
  }

  private getNormalizedProducts(products: IStreamProduct[]) {
    return products.map(product => {
      product['image'] = this.cld.image(this.cloudFolder + "/" + product.imagePath + "/" + product.imageName);
      product.image.resize(fill().width(64).height(64)).roundCorners(byRadius(4));

      return product;
    });
  }

  private getProducts(sellType?: ProductSellType): Observable<IStreamProduct[]> {
    const url = `/stream/streamProductList?streamuuid=${this.streamDetails.uuid}${sellType ? `&sellType=${sellType}` : ''}&limit=100&offset=0`;
    return this.service.getProducts(url).pipe(
      filter(res => res?.status === 1),
      map(res =>this.getNormalizedProducts(res.data.products) )
    )
  }

  private getStreamProducts(withMenuProducts = true): void {
    forkJoin([
      this.getProducts(),
      withMenuProducts && this.streamListId ? this.getMenuInfo(this.streamListId) : of(this.streamListId ? this.selectedProductMenu : null),
    ]).subscribe(async ([streamProducts, productMenu]) => {
      this.selectedProductMenu = productMenu;

      const {
        auctionProducts, buyNowProducts, giveAwayProducts
      } = this.getGroupedProducts(streamProducts);

      this.allAuctionProducts = [...auctionProducts];
      this.auctionProducts = [...auctionProducts];

      this.allBuyNowProducts = [...buyNowProducts];
      this.buynowProducts = [...buyNowProducts];

      this.allGiveawayProducts = [...giveAwayProducts];
      this.giveawayProducts = [...giveAwayProducts];

      this.updateSelectedProductsInDropdown();

      if (!this.isStreamOn && this.startStream) {
        await this.getRtcToken(this.options.host, "publisher", "userAccount");
        this.isStreamOn = true;
      }
    })
  }

  private filterSelectedProductsInDropdown(id: number): void {
    this.selectedProducts = this.selectedProducts.filter(item => item.value.product_id !== id);
  }

  private updateSelectedProductsInDropdown(): void {
    this.selectedProducts = [
      ...this.allAuctionProducts,
      ...this.allBuyNowProducts,
      ...this.allGiveawayProducts
    ].map(product => ({
      value: product,
      label: product.name
    }))
    .filter(product => product.value.streamUuid === this.loadedStreamData.uuid);
  }

  private getGroupedProducts(products: IStreamProduct[]) {
    const auctionProducts = [];
    const buyNowProducts = [];
    const giveAwayProducts = [];
  
    products.forEach(product => {
      switch(product.sell_type) {
        case ProductSellType.AUCTION:
          auctionProducts.push(product);
          break;
        case ProductSellType.BUY_NOW:
          buyNowProducts.push(product);
          break;
        case ProductSellType.GIVEAWAY:
          giveAwayProducts.push(product);
          break;
      }
    });

    return {
      auctionProducts, buyNowProducts, giveAwayProducts
    };
  }

  private getOldChangeMessages(): void {
    this.service.getOldChatMessages(this.streamDetails.uuid).subscribe(result => {
      if (result && result.response) {
        result.response.forEach(({ id, message, profileUrl, roomId, userId }) => {
          
          if(message && message != '') {
            let messageObject = {
              message,
              id: id,
              userId: userId,
              profileUrl: profileUrl,
              roomId: roomId,
              email: userId == 'HOST' ? this.userDetails?.email : ''
            };
  
            if(profileUrl) {
              this.senderImage = this.cld.image(this.cloudFolder + "/"+ profileUrl);
              this.senderImage.resize(fill().width(36).height(36)).border(byRadius(18));
            } else {
              this.senderImage = '';
            }
  
            messageObject['image'] = this.senderImage;
  
            this.messageData.push(messageObject);
            this.displayUserCount();
          }
        });
      }
    });
  }

  private getMenuInfo(listId: number): Observable<IProductMenu> {
    return this.productsMenusService.getMenus({ids: [listId]}).pipe(
      map(response => {
        if (response.status === 1) {
          return response.data[0];
        }
      })
    );
  }

  private getProductMenus(): void {
    this.productsMenusService.getMenus({}).subscribe(res => {
      if (res.status === 1) {
        this.dropdownProductMenus = [
          {
            value: null,
            label: 'Not Selected'
          },
          ...res.data.map(menu => ({
            value: menu,
            label: menu.listName
          }))
        ];
      }
    });
  }

  private getDropdownProducts(): void {
    this.productsService.getProductList({
      status: 1,
      sellType: `${ProductSellType.AUCTION},${ProductSellType.BUY_NOW},${ProductSellType.GIVEAWAY}`
    }).subscribe(data => {
      if (data.status === 1) {
        this.dropdownProducts = data.data.reduce((acc, product) => {

          if (product.quantity > 0) {
            acc.push({
              value: {
                ...product,
                product_id: product.productId
              },
              label: product.name
            });
          }

          return acc;
        }, []);
      }
    })
  }

  private getCategories() {
    return this.service.getCategories().pipe(
      map(res => res.status === 1 ? res?.data : [])
    )
  }
}