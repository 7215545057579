import { IStreamProduct } from '../../pages/component/stream/interfaces';

export interface ResponseInterface {
  message: string;
  data: any;
  status: Number;
}

export interface IMenuItem extends ISubmenuItem {
  submenu: ISubmenuItem[]
}

export interface ISubmenuItem {
  id: number;
  path: string,
  title: string,
  image: string,
  activeImage: string
}

interface VendorCategory {
  productId: number;
  categoryId: number;
  categoryName: string;
}

interface IProductImage {
  productId: number,
  image: string,
  containerName: string,
  defaultImage: null
};

export interface IProduct {
  vendorProductId: number;
  vendorProductCommission: number;
  quotationAvailable: number;
  approvalFlag: number;
  vendorId: number;
  productId: number;
  pincodeBasedDelivery: number;
  name: string;
  sku: string;
  sellType: ProductSellType;
  productprice: string;
  quantity: number;
  vendorName: string;
  sortOrder: number | null;
  isActive: number;
  productSlug: string;
  createdDate: string;
  keywords: string;
  attributeKeyword: string | null;
  image: string;
  containerName: string;
  skuId: string | null;
  price: string;
  modifiedPrice: string;
  productDiscount: string | null;
  productSpecial: string | null;
  vendorCategory: VendorCategory[];
  pricerefer: string;
  flag: string;
  earnings: string | null;
  productImage?: IProductImage[];
}

export enum ProductSellType {
  OFFLINE = 'checkout',
  BUY_NOW = 'buy_now',
  AUCTION = 'auction',
  GIVEAWAY = 'giveaway'
}

export interface IStream {
  uuid: string;
  title: string;
  description: null;
  moderator: boolean;
  moderator_id: number[];
  moderatorData: {id: number; username: string}[];
  category_id: number;
  subCategory_id: number;
  scheduleDate: string;
  scheduletime: string;
  isExplicit: 1 | 0;
  isMute: number;
  isLike: number;
  isFollow: null;
  preview_image: string | {image: string; containerName: string}[];
  preview_image_path: string;
  preview_gif: string;
  preview_gif_path: string;
  appId: string;
  streamAPPID: string;
  vendorDetails: {
    company_name: string,
    stream_channel: string,
    message_channel: string,
    notification_channel: string,
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    vendor_id: number,
    avatar: string,
    avatar_path: string,
    avgRating: number,
    ratingCount: string,
    follower_count: number
  };
  products: IStreamInnerProduct[],
  listId: number;
  IsBlocked: number
}

export interface IStreamInnerProduct {
  streamproductuuid: string;
  product_id: string;
  sku: string;
  upc: null;
  stock_status_id: number;
  quantity: number;
  price: string;
  name: string;
  ecom_type: string;
  sell_type: ProductSellType;
}

export interface ICategory {
  categoryId: number;
  sortOrder: number;
  parentInt: number;
  name: string;
  image: null;
  imagePath: null;
  metaTagTitle: string;
  metaTagDescription: string;
  metaTagKeyword: string;
  isActive: number;
  createdDate: string;
  levels: string;
}