import { Component,ChangeDetectorRef, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { PaymentSandbox } from '../../../../../../core/payment/payment.sandbox';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {PaymentService} from '../../../../../../core/payment/payment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit {
  public keyword = '';
  public filterForm: FormGroup;
  public filter = false;
  public miniDate: any;
  public dateError: string;
  public startDate: any;
  public isRequired = false;
  public endDate: any;
  public selectedAll: any;
  @ViewChild(NgbDropdown)
  public dropdown: NgbDropdown;
  public paymentArray = [];
  public filterData: any = [];
  public filterDataId = [];
  public limit = 10;
  public offset: any = 0;
  public currentPage: any = 1;
  public config: SwiperConfigInterface = {};
  public userDetails = JSON.parse(localStorage.getItem('vendor-settings'));
  public currencyCode = this.userDetails.currencyCode;
  public queryData: any = {};

  ledgerData : any =[] ;
  totalCount: any;

  constructor(
    public service : PaymentService,
    public paymentSandbox: PaymentSandbox,
    public formBuilder: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private ref : ChangeDetectorRef,
    private toaster: ToastrService,
  ) { 
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  ngOnInit(): void {
    this.currentPage = 1;
    this.offset = this.limit * (this.currentPage - 1);
    const params: any = {};
    params.limit = this.limit;
    params.offset = this.offset;

    if (this.keyword) {
      params.keyword = this.keyword;
    }

    if (this.startDate) {
      params.startDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate();
    }

    if (this.endDate) {
      params.endDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate();
    }

    params.isRefresh = false;
    params.keyUp = true;

    this.ledgerList(params);
  }

  ledgerList(params) {
    this.service.LedgerList(params).subscribe((data :any) => {
      if(data) {
        this.ledgerData = data.data;
        this.totalCount = data.totalCount;
        // this.ref.detectChanges();
      }
    });
  }
  pageChange(event) {
    this.currentPage = event;
    this.offset = this.limit * (event - 1);
    const params: any = {};
    params.limit = this.limit;
    params.offset = this.offset;
    params.keyword = this.keyword;
    params.startDate = this.startDate ? this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate() : '';
    params.endDate = this.endDate ? this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate() : '';
    params.isRefresh = false;
    params.keyUp=true;
    this.ledgerData = [];
    this.ledgerList(params);
  }

  goToOrders(id) {
    this.router.navigate(['/orders/order-details',id],
      { 
        queryParams: { 
          isOrder : 2
        } 
      }
    );
  }
  Ids: Number[] = [];
  selectAll(event) {
    if (event.currentTarget.checked) {
      this.ledgerData.forEach(data => {
        if (data) {
          this.Ids.push(data.orderId);
        }
      });
    } else {
      this.Ids = [];
    }
  }

  addProductToArr(event: any, orderId: number) {
    if (event.currentTarget.checked) {
      this.Ids.push(orderId);
    } else {
      const idx = this.Ids.indexOf(orderId);
      if (idx > -1)
        this.Ids.splice(idx, 1);
    }
  }

  ExportLedger() {
    let params: any = {};
    params.limit = this.limit;
    params.offset = this.offset;
    params.keyword = this.keyword;
    params.startDate = this.startDate || '';
    params.endDate = this.endDate || '';
    params.isRefresh = false;
    params.keyUp=true;
    params.excel = true;
    params.orderIds = "["+this.Ids.toString()+"]";
    this.service.ExportLedger(params).subscribe((result) => {
      try {
        const outputFilename = `${Date.now()}.xls`;
        const url = URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
          throw Error(error);
      }
    });
  }

    /**
   * @method searchLedger -- Get Data based on Search
   * @param value -- value
   */
     searchLedger() {
      this.offset = 0;
      if(this.startDate && this.endDate) {
        const params: any = {};
        params.limit = this.limit;
        params.offset = this.offset;
        params.keyword = this.keyword
        params.startDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate();
        params.endDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate();
        this.ledgerList(params);
      }
      else {
        const params: any = {};
        params.limit = this.limit;
        params.offset = this.offset;
        params.keyword = this.keyword;
        params.startDate = this.startDate || '';
        params.endDate = this.endDate || '';
        this.ledgerList(params);
      }
    }
    /**
  * @method -- for ios issue search
  * @param event 
  */
  onReturn(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 229 || charCode === 13 || event.key === 'Enter') {
      this.searchLedger();
    }
  }

  /**
   * @method -- get list of ledger list after clear search
   * @param event 
   */
   onKeydown(event) {
    if(this.keyword.length === 1) {
      if (event.key === "Backspace") {
        this.keyword = '';
        this.offset = 0;
        if(this.startDate && this.endDate) {
          const params: any = {};
          params.limit = this.limit;
          params.offset = this.offset;
          params.keyword = this.keyword;
          params.startDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate();
          params.endDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate();
          params.isRefresh = false;
          params.keyUp=true;
          this.ledgerList(params);
        }
        else {
          const params: any = {};
          params.limit = this.limit;
          params.offset = this.offset;
          params.keyword = this.keyword;
          params.startDate = this.startDate || '';
          params.endDate = this.endDate || '';
          params.isRefresh = false;
          params.keyUp=true;
          this.ledgerList(params);
        }
      }
    }
  }

  clearFilter() {
    this.keyword = '';
    this.startDate = null;
    this.endDate = null;
    this.offset = 0;
    const params: any = {};
    params.limit = this.limit;
    params.offset = this.offset;
    params.keyword = '';
    params.startDate = '';
    params.endDate = '';
    params.isRefresh = false;
    params.keyUp=false;
    this.ledgerList(params);
  }

  applyFilter() {
    if(!this.startDate && !this.endDate) {
      this.toaster.error('Please Provide Start Date and End Date');
    }
    else if(!this.startDate) {
      this.toaster.error('Choose Start Date First');
      
    }
    else if(!this.endDate) {
      this.toaster.error('Choose End Date');
    }
    else {
      this.offset = 0;
      this.ledgerData = [];
      const params: any = {};
      params.limit = this.limit;
      params.offset = this.offset;
      params.keyword = this.keyword;
      params.startDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate();
      params.endDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate();
      params.isRefresh = false;
      params.keyUp=false;
      this.ledgerList(params);
    }
  }
}