<mat-card>
    <div class="form-head-title flex-sb items-center flex">
        <h3 class="title-text">Start Auction</h3> 
        <img class="close-btn" src="../../../../../../assets/images/cancel-icon.svg" alt=""  (click)="closeDialog()" />
    </div>

    <!-- 
        <form [formGroup]="createAuctionFormGroup" (ngSubmit)="onSubmit()">
            <mat-form-field class="form-wrapper">
                <input class="control-input" type="number" min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Price (in $)" formControlName="price" required>
                <mat-error *ngIf="createAuctionFormGroup.controls['price'].hasError('required')">Price is required</mat-error>
            </mat-form-field>
            <mat-form-field class="form-wrapper">
                <input class="control-input" matInput type="number" min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Time (in seconds)" formControlName="time" required>
                <mat-error *ngIf="createAuctionFormGroup.controls['time'].hasError('required')">Time is required</mat-error>
            </mat-form-field>
            <button class="primary-btn" mat-raised-button color="primary" type="submit">Start Auction</button>
        </form> 
    -->

    <form [formGroup]="createAuctionFormGroup" class="form-wrapper">
        <div class="form-col startaction-time">
            <div class="left-part">
                <label>Starting Bid (in $)*</label>
                <input class="control-input" type="number" min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Price (in $)" formControlName="price" required>
                <span style="color: red;" *ngIf="createAuctionFormGroup.controls['price'].hasError('required')">Price is required</span>
            </div>
            <div class="right-part">
                <label>Time</label>
                <div class="control-input time" (click)="isActive ? timeInactive() : timeActive()">{{time}}</div>
            </div>
        </div>
        <!-- 
            <div class="form-col">
                <label>Time (in second)*</label>
                <input class="control-input" type="number" min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Time (in seconds)" formControlName="time" required>
                <span style="color: red;" *ngIf="createAuctionFormGroup.controls['time'].hasError('required')">Time is required</span>
            </div> 
        -->
        <div class="form-col" *ngIf="!isActive">
            <div class="time-wrp control-input">
                <button class="time-btn" [ngClass]="time === '15s' ? 'active-btn' :  ''" (click)="addTime('15s')">15s</button>
                <button class="time-btn" [ngClass]="time === '30s' ? 'active-btn' :  ''" (click)="addTime('30s')">30s</button>
                <button class="time-btn" [ngClass]="time === '45s' ? 'active-btn' :  ''" (click)="addTime('45s')">45s</button>
                <button class="time-btn" [ngClass]="time === '1mins' ? 'active-btn' :  ''" (click)="addTime('1mins')">1mins</button>
                <button class="time-btn" [ngClass]="time === '1mins 30s' ? 'active-btn' :  ''" (click)="addTime('1mins 30s')">1mins 30s</button>
                <button class="time-btn" [ngClass]="time === '2mins' ? 'active-btn' :  ''" (click)="addTime('2mins')">2mins</button>
                <button class="time-btn" [ngClass]="time === '2mins 30s' ? 'active-btn' :  ''" (click)="addTime('2mins 30s')">2mins 30s</button>
                <button class="time-btn" [ngClass]="time === '3mins' ? 'active-btn' :  ''" (click)="addTime('3mins')">3mins</button>
                <button class="time-btn" [ngClass]="time === '3mins 30s' ? 'active-btn' :  ''" (click)="addTime('3mins 30s')">3mins 30s</button>
                <button class="time-btn" [ngClass]="time === '4mins' ? 'active-btn' :  ''" (click)="addTime('4mins')">4mins</button>
                <button class="time-btn" [ngClass]="time === '4mins 30s' ? 'active-btn' :  ''" (click)="addTime('4mins 30s')">4mins 30s</button>
                <button class="time-btn" [ngClass]="time === '5mins' ? 'active-btn' :  ''" (click)="addTime('5mins')">5mins</button>
            </div>
        </div>
        <div class="content-setting">
          <div class="inner-content flex justify-content-between">
            <div class="left-part">
              <h5>Sudden Death</h5>
              <p class="description">
                This means when you're down to 00:01, the last person to bid wins!
              </p>
            </div>
            <div class="right-part">
              <label class="switch">
                <input type="checkbox" class="control-input" [(checked)]="sd" (change)="toggleChange($event)" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="action-btn">
            <button class="border-btn" (click)="closeDialog()">Cancel</button>
            <button class="primary-btn" type="submit" (click)="onSubmit()">Start Auction</button>
       </div>
    </form>
</mat-card>