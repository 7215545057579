<app-breadcrumbs></app-breadcrumbs>
    <div class="payments-wrapper">
        <div class="text-title">
            <h3>Payments</h3> 
        </div> 
     <div class="container">
        <div class="flex search-exp">
        <div class="search-left flex">
            <div class="Search-input">
                <input type="search" placeholder="Search Order Id, Name" class="input-control" [(ngModel)]="keyword" (keydown.backspace)="onKeydown($event)" (keydown.enter)="searchPayment()" (keypress)="onReturn($event)">
                <button class="Search-input-btn" (click)="searchPayment()">
                    <svg width="15" height="15"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="icon" d="M14.5001 13.7931L10.7241 10.0171C11.6315 8.92772 12.0839 7.53048 11.9874 6.11601C11.8908 4.70154 11.2527 3.37875 10.2057 2.42282C9.15867 1.46688 7.78343 0.951395 6.36604 0.983601C4.94864 1.01581 3.59823 1.59322 2.59573 2.59573C1.59322 3.59823 1.01581 4.94864 0.983601 6.36604C0.951395 7.78343 1.46688 9.15867 2.42282 10.2057C3.37875 11.2527 4.70154 11.8908 6.11601 11.9874C7.53048 12.0839 8.92772 11.6315 10.0171 10.7241L13.7931 14.5001L14.5001 13.7931ZM2.00006 6.50006C2.00006 5.61004 2.26398 4.74001 2.75844 3.99999C3.25291 3.25997 3.95572 2.68319 4.77798 2.3426C5.60025 2.00201 6.50505 1.91289 7.37796 2.08652C8.25088 2.26016 9.0527 2.68874 9.68204 3.31808C10.3114 3.94741 10.74 4.74924 10.9136 5.62215C11.0872 6.49507 10.9981 7.39987 10.6575 8.22213C10.3169 9.0444 9.74015 9.74721 9.00012 10.2417C8.2601 10.7361 7.39007 11.0001 6.50006 11.0001C5.30699 10.9987 4.16317 10.5242 3.31954 9.68058C2.47591 8.83695 2.00138 7.69313 2.00006 6.50006Z" fill="#5C646C"></path>
                    </svg>
                </button>
            </div>
           
        </div>
       <div class="search-right-wrapper">
        <!-- <button class="all-status  profile flex flex-ce align-items-center">
            <select name="date" id="date" [(ngModel)] = "transactionFilterId" (change)="selectChangeHandler($event)">
                <option value="" selected disabled hidden>--Select--</option>
                <option *ngFor="let filter of transactionFilter" value = {{filter.id}}>
                    {{filter.title}}
                </option>
            </select>
        </button> -->
        <div class="date-wrapper">
            <mat-form-field appearance="fill" class="example-form-field">
                <label class="label">Date Range</label>
                <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
                    <input matEndDate placeholder="End date" [(ngModel)]="endDate">            
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker>
                    <mat-date-range-picker-actions>
                        <button mat-raised-button color="primary" matDateRangePickerApply (click)="applyFilter()">Apply</button>
                    </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </mat-form-field>
        </div>
        
        <div class="right-wrapper">
            <div *ngIf="paymentArray.length > 0 && filterDataId.length === 0" class="search-right flex" [title]=" filterDataId.length === 0 ? 'Bulk export' : 'Export'">
                <a href="javascript:void(0)" class="flex  border-btn" (click)="exportAllPayments()"> 
                    <img title="Export" alt="Export" src="assets/imgs/export.svg">
                    Export
                </a>
                <span class="multi-spin" *ngIf="(paymentSandbox.exportPaymentLoading$ | async) || (paymentSandbox.MultiplePaymentExportLoading$ | async)">
                    <i class="fas fa-spinner fa-spin fa-2x" style="color: blue;"></i>
                </span>
            </div>
            <div *ngIf="paymentArray.length > 0 && filterDataId.length > 0" class="search-right flex" [title]=" filterDataId.length === 0 ? 'Bulk export' : 'Export'">
                <a href="javascript:void(0)" class="flex ripple border-btn" (click)="exportPayment()">
                    <img title="Export" alt="Export" src="assets/imgs/export.svg" style="width:16px"> 
                    Export
                </a>
                <span class="multi-spin" *ngIf="(paymentSandbox.exportPaymentLoading$ | async) || (paymentSandbox.MultiplePaymentExportLoading$ | async)">
                    <i  class="fas fa-spinner fa-spin fa-2x" style="color: blue;"></i>
                </span>
            </div>
            
        </div>
        <button class="btn btn-default clear" (click)="clearFilter()" *ngIf="keyword || startDate || endDate">
            Clear Filter
        </button>
   </div>
</div>
<span *ngIf="filter" class="date-tag">{{startDate}} to {{endDate}}
    <img class="cursor" alt="Reset Filter" title="Reset Filter"   (click)="resetFilter()" src="assets/imgs/close-white-ico.png">
</span>
<!---->

<div class="product-list-wrap">
    <!-- <div class="loading" *ngIf="(paymentSandbox.paymentListLoading$ | async) || (paymentSandbox.makePaymentArchiveLoading$ | async)">
        <app-loader></app-loader>
    </div> -->
    <div class="no-data" *ngIf="paymentArray.length === 0">
        <span>No Payments found</span> </div>
    <div class="table-responsive " *ngIf="paymentArray.length > 0">
        <table class="table-list" [ngClass]="{'pay-table': (paymentSandbox.downloadCsvLoading$ | async)}">
            <thead>
                <tr>
                    <th>
                        <div class="form-group" title="Click To Export">
                            <input type="checkbox" id="checkbox1" style="display: block;" [(ngModel)]="selectedAll" (change)="selectAll($event)" [ngModelOptions]="{standalone: true}">
                        </div>
                    </th>
                    <th>Order ID</th>
                    <th>Date & Time</th>
                    <th>Buyer</th>
                    <th class="amt text-center">Amount</th>
                    <th class="cmsn text-center">Tax</th>
                    <th class="cmsn text-center">Shipping</th>
                    <th class="amt text-center">Total Amount</th>
                    <th class="cmsn text-center">Commission</th>
                    <th class="amt text-center">Seller Amount</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let array of paymentArray; let i = index;">
                    <td>
                        <div class="form-group" title="Click To Export">
                            <input type="checkbox" [id]="array.vendorOrderId" style="display: block;" [(ngModel)]="array.selected" (change)="checkIfAllSelected()" [value]="array.productId" [ngModelOptions]="{standalone: true}">
                        </div>
                    </td>
                    <td class="cursor" (click)="goToOrders(array?.vendorOrderId
                    )">
                        <h3 class="id-col">{{array?.vendorOrderId}}</h3>
                    </td>
                    <td>
                        <p>{{array?.createdDate | date : 'MMM d, y, h:mm a'}}</p>    
                    </td>
                    <td>
                        <div class="flex buyer">
                            <span class="flex logo">{{((array?.customerFirstName).charAt(0)) | uppercase}}</span>
                            <p><span class="name">{{array?.customerFirstName}}</span>{{array?.shippingCity}}, {{array?.shippingCountry}}
                            </p>
                        </div>
                    </td>
                    <td class="amt text-center">
                        {{array?.currencySymbolLeft}} {{array?.amount | currency:currencyCode:''}} {{array?.currencySymbolRight}}
                    </td>
                    <td class="cmsn text-center">
                        {{array?.currencySymbolLeft}} {{array?.tax  | currency:currencyCode:''}} {{array?.currencySymbolRight}}
                    </td>
                    <td class="cmsn text-center">
                        {{array?.currencySymbolLeft}} {{array?.shippingCharge ?array?.shippingCharge : 0 | currency:currencyCode:''}} {{array?.currencySymbolRight}}
                    </td>
                    <td class="amt text-center">
                        {{array?.currencySymbolLeft}} {{array?.NetAmount | currency:currencyCode:''}} {{array?.currencySymbolRight}}
                    </td>
                    <td class="cmsn text-center">{{array?.currencySymbolLeft}} {{(array?.totalCommission ? array.totalCommission : 0) | currency:currencyCode:''}} {{array?.currencySymbolRight}}</td>
                    <td class="amt text-center">
                        {{array?.currencySymbolLeft}} {{array?.sallerAmount | currency:currencyCode:''}} {{array?.currencySymbolRight}}
                    </td>
                    <!-- <td class="text-center">
                        <img *ngIf="array.makeSettlement !== 0" src="assets/imgs/checked.svg">
                        <img *ngIf="array.makeSettlement === 0" src="assets/imgs/close-red.svg">
                    </td> 
                    <td class="text-center">

                        <div class="btn-group">
                            <button type="button" class="dropdown-toggle" data-toggle="dropdown">
                                <img src="../../../../../../../assets/images/order-menu.svg" alt="">
                            </button>
                            <ul class="dropdown-menu invoice-menu" role="menu">
                                <li (click)="downloadInvoice(array?.vendorOrderId)"><a href="javascript:void(0)" class="flex"> Invoice</a></li>
                                <li><a href="javascript:void(0)" class="flex" (click)="makeAchive(array?.vendorPaymentId)"> Archive</a></li>
                            </ul>
                        </div>
                    </td>
                -->
                </tr>
            </tbody>
            <!-- <i class="fas inv-loader fa-spinner fa-spin fa-2x" *ngIf="(paymentSandbox.downloadCsvLoading$ | async)" style="color: blue;"></i> -->

        </table>

    </div>


</div>
<div class="pager" style="right: 0;">
    <app-pager (pageChange)="pageChange($event)" [pageSize]="limit" [currentPage]="currentPage" [counts]="count"></app-pager>
</div>
</div>
</div>