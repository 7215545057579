import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '../../../../../../core/providers/api/api';
import { IProductMenu } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProductMenusService extends Api {

  private basUrl = this.getBaseUrl();

  private get url(): string {
    return this.basUrl + '/vendor-product/product-list';
  }

  public getMenus(params: {
    offset?: number;
    limit?: number;
    keyword?: string;
    ids?: number[]
  }): Observable<{data: IProductMenu[], status: number}> {
    return this.http.get<{data: IProductMenu[], status: number}>(this.url, { params : params });
  }

  public getAllMenusCount(): Observable<{data: number; status: number}> {
    return this.http.get<{data: number; status: number}>(this.url, { params : {count: 1} });
  }

  public createMenu(listName: string): Observable<{data: IProductMenu, status: number}> {
    return this.http.post<{data: IProductMenu, status: number}>(this.url, {listName});
  }

  public updateMenu(listId: number, changes: {listName: string}): Observable<{status: number}> {
    return this.http.put<{status: number}>(`${this.url}/${listId}`, changes);
  }

  public deleteMenu(listId: number): Observable<{status: number}> {
    return this.http.delete<{status: number}>(`${this.url}/${listId}`);
  }

  public changeProducts(listId: number, productIds: number[], action: 'set' | 'remove'): Observable<{message: string, status: number}> {
    return this.http.put<{message: string, status: number}>(`${this.url}/${listId}/products`, {productIds: productIds.join(','), action});
  }

}