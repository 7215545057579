export const environment = {
  production: true,
  baseUrl: 'https://backend.splurky.com/api',
  imageUrl: 'https://backend.splurky.com/api/media/video-preview-s3',
  productUrl: 'https://backend.splurky.com',
  pluginUrl: 'https://backend.splurky.com',
  socUrl: 'https://notification.splurky.com/events/start?channel=',
  labelUrl: 'https://storage.googleapis.com/splurky-assets/',
  cloudName: 'splurky',
  cloudFolder: 'splurky',
  storeProfileUrl : '',
  streamUrl : '',
  videoBucketName: 'dev-blazing-card-agora-streams',
  baseBucketUrl: 's3.amazonaws.com',
  chatUrl: 'https://chat.splurky.com',
};